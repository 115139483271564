import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import { Location } from "../../types/location";
import { Typography } from "@mui/material";
import MapPreview from "../../components/MapPreview";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  marginTop: 40,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface LocationDrawerProps {
  data: Location;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function LocationDrawer(props: LocationDrawerProps) {
  const { data, open, setOpen } = props;
  const [lng, lat] = React.useMemo(
    () => data?.coordinate?.coordinates || [],
    [data]
  );

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  return (
    <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
      <Box sx={{ width: 450, mb: 8 }} role="presentation">
        <DrawerHeader>
          <ClearIcon
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          />
        </DrawerHeader>
        <MapPreview lat={lat} lng={lng} />
        <List>
          <ListItem>
            <Typography variant="h5">{data?.name}</Typography>
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Site Number`}
              secondary={`${data?.site_number || "n/a"}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Address`}
              secondary={`${data?.address_1}, ${data?.city}, ${data?.state} ${data?.zip}`}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Country`}
              secondary={data?.country?.name || "n/a"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Contact Name`}
              secondary={data?.primary_contact_name || "n/a"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Contact Phone`}
              secondary={data?.primary_contact_phone_number || "n/a"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={`Contact Email`}
              secondary={data?.primary_contact_email || "n/a"}
            />
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
}
