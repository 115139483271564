import { ROLE_COMPANY_ID } from "../configs/api";
import { Manager } from "../types/users";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_MANAGER } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_MANAGER],
});

export const managerApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getManagers: builder.query<
      QueryResponse<Manager>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ page, pageSize, sortField, sortMode, search }) => ({
        url: "users",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `first_name|asc`,
          // withTrashed: true,
          role: ROLE_COMPANY_ID,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_MANAGER, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_MANAGER, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_MANAGER, id })),
        ];
      },
    }),
    getManagerById: builder.query<Manager, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `users/${id}`,
        method: "GET",
        params: {
          load: "photo",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_MANAGER, id }],
    }),
    addManager: builder.mutation<Manager, Omit<Manager, "id">>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `users`,
          method: "POST",
          body: {
            role: ROLE_COMPANY_ID,
            ...body,
          },
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_MANAGER, id: "PARTIAL-LIST" },
      ],
    }),
    editManager: builder.mutation<
      Manager,
      Partial<Manager> & Pick<Manager, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `users/${body.id}`,
          method: "PUT",
          body: {
            role: ROLE_COMPANY_ID,
            ...body,
          },
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_MANAGER, id: data.id },
        { type: TAG_TYPE_MANAGER, id: "PARTIAL-LIST" },
      ],
    }),
    deleteManager: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_MANAGER, id },
        { type: TAG_TYPE_MANAGER, id: "PARTIAL-LIST" },
      ],
    }),
    restoreManager: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `users/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_MANAGER, id },
        { type: TAG_TYPE_MANAGER, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetManagersQuery,
  useGetManagerByIdQuery,
  useAddManagerMutation,
  useEditManagerMutation,
  useDeleteManagerMutation,
  useRestoreManagerMutation,
} = managerApi;
