import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import manage360 from "../../images/manage360.png";
import { Box } from "@mui/material";
import { Login } from "@mui/icons-material";

const SignupCompleted = () => {
  const navigate = useNavigate();
  useTitle("Signup Completed");

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={manage360} height={60} />
      <Typography variant={"h6"} sx={{ textAlign: "center", mt: 2 }}>
        Thank You For Registering!
      </Typography>
      <Button
        size="large"
        variant="outlined"
        startIcon={<Login />}
        sx={{ mt: 4 }}
        onClick={() => navigate("/login")}
      >
        Back to Login
      </Button>
    </Box>
  );
};
export default SignupCompleted;
