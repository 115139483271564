import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PeopleIcon from "@mui/icons-material/People";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AddCircle,
  ApprovalSharp,
  ArticleOutlined,
  CreateRounded,
  CalendarMonthOutlined,
  ViewListOutlined,
  ForwardToInboxOutlined,
} from "@mui/icons-material";
import { useCompany } from "../hooks/useCompany";
import { CompanyStatus } from "../types/company";

const SidebarMenu = () => {
  const navigate = useNavigate();
  const [jobExpanded, setJobExpanded] = React.useState(false);
  const [talentExpanded, setTalentExpanded] = React.useState(false);
  const [reportExpanded, setReportExpanded] = React.useState(false);
  const [settingsExpanded, setSettingsExpanded] = React.useState(false);
  const company = useCompany();

  const { pathname } = useLocation();
  const page = React.useMemo(() => {
    const parts = pathname.split("/");
    const page = parts.length >= 1 ? parts[1] : "?";
    return page;
  }, [pathname]);

  return (
    <List component="nav">
      <ListItemButton
        onClick={() => navigate("/dashboard")}
        selected={page == "dashboard"}
      >
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton onClick={() => setJobExpanded(!jobExpanded)}>
        <ListItemIcon>
          <HomeRepairServiceIcon />
        </ListItemIcon>
        <ListItemText
          primary="Job Menu"
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {jobExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={jobExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/jobs/create")}
            disabled={company?.status != CompanyStatus.ACTIVE}
            selected={page == "jobs/create"}
          >
            <ListItemIcon>
              <CreateRounded />
            </ListItemIcon>
            <ListItemText primary="Create New Job" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/calendar")}
            selected={page == "calendar"}
          >
            <ListItemIcon>
              <CalendarMonthOutlined />
            </ListItemIcon>
            <ListItemText primary="Jobs Calendar" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/jobs")}
            selected={page == "jobs"}
          >
            <ListItemIcon>
              <ApprovalSharp />
            </ListItemIcon>
            <ListItemText primary="Go to Jobs" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/locations")}
            selected={page == "locations"}
          >
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Locations" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/jobs/job-templates")}
            selected={page == "jobs/job-templates"}
          >
            <ListItemIcon>
              <ArticleOutlined />
            </ListItemIcon>
            <ListItemText primary="Job Templates" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/jobs/shift-templates")}
            selected={page == "jobs/shift-templates"}
          >
            <ListItemIcon>
              <ArticleOutlined />
            </ListItemIcon>
            <ListItemText primary="Shift Templates" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => setTalentExpanded(!talentExpanded)}>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText
          primary="Employees Menu"
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {talentExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={talentExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/employees")}
            selected={page == "employees"}
          >
            <ListItemIcon>
              <ViewListOutlined />
            </ListItemIcon>
            <ListItemText primary="Show List" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/employees/map")}
            selected={page == "map"}
          >
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Show Map" />
          </ListItemButton>
          <ListItemButton
            onClick={() => navigate("/employees/invite")}
            selected={page == "import"}
          >
            <ListItemIcon>
              <ForwardToInboxOutlined />
            </ListItemIcon>
            <ListItemText primary="Send Invites" />
          </ListItemButton>
        </List>
      </Collapse>
      <ListItemButton onClick={() => setSettingsExpanded(!settingsExpanded)}>
        <ListItemIcon>
          <SettingIcon />
        </ListItemIcon>
        <ListItemText
          primary="Settings"
          primaryTypographyProps={{ fontWeight: 600 }}
        />
        {settingsExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={settingsExpanded} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ backgroundColor: "#eee" }}
        >
          <ListItemButton
            onClick={() => navigate("/managers")}
            selected={page == "managers"}
          >
            <ListItemIcon>
              <AddCircle />
            </ListItemIcon>
            <ListItemText primary="Add Managers" />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
};
export default SidebarMenu;
