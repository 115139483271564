import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ROLE_COMPANY_ID } from "../../configs/api";
import { authApi } from "../../services/auth.service";
import { OauthToken } from "../../types/oauth";
import { Manager, User } from "../../types/users";

export interface AuthState {
  user: Manager | null;
  token: OauthToken | null;
}

const initialState: AuthState = {
  user: null,
  token: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<Manager | null>) => {
      state.user = action.payload;
    },
    setToken: (state, action: PayloadAction<OauthToken | null>) => {
      state.token = action.payload;
    },
    clearSession: (state) => {
      state.token = null;
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        authApi.endpoints.login.matchFulfilled,
        (state, { payload }) => {
          state.token = {
            accessToken: payload.access_token,
            refreshToken: payload.refresh_token,
            expiresIn: payload.expires_in,
          };
        }
      )
      .addMatcher(
        authApi.endpoints.getUser.matchFulfilled,
        (state, { payload }) => {
          state.user = payload;
        }
      )
      .addMatcher(authApi.endpoints.getUser.matchRejected, (state) => {
        // get user failed (token expired etc) ?? logout totally
        state.user = null;
        state.token = null;
      })
      .addMatcher(
        authApi.endpoints.loadUser.matchFulfilled,
        (state, { payload }) => {
          if (payload.role_id == ROLE_COMPANY_ID) {
            state.user = payload;
          } else {
            console.error("Not a company user! Aborted");
            state.user = null;
            state.token = null;
            //TODO: how to throw error from here?
          }
        }
      )
      .addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
        state.token = null;
        state.user = null;
      });
  },
});

export const { setUser, setToken, clearSession } = authSlice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectCompany = (state: RootState) => state.auth.user?.company;

export default authSlice.reducer;
