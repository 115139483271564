import * as React from "react";
import { setTitle } from "../app/uiSlice";
import { useAppDispatch } from "./store";

const DEFAULT_TITLE = "Workz360 App";

export function useTitle(title: string, pageTitle?: string) {
  const dispatch = useAppDispatch();
  dispatch(setTitle(title));

  const _pageTitle = pageTitle || title;

  React.useEffect(() => {
    const prevTitle = document.title;
    document.title = !!_pageTitle
      ? `${_pageTitle} | ${DEFAULT_TITLE}`
      : DEFAULT_TITLE;
    return () => {
      document.title = prevTitle;
    };
  });
}
