import React, { useState } from "react";
import { Box, Button, ButtonGroup, colors } from "@mui/material";
import { useGetProjectsQuery } from "../../services/project.service";
import MonthlyCalendar from "./monthlyCalendar/MonthlyCalendar";
import WeeklyCalendar from "./weeklyCalendar/WeeklyCalendar";
import "./styles.css";
const CalendarPage = () => {
  const [selectedOption, setSelectedOption] = useState("Week");
  const { data: dataProjects, isLoading: isLoadingDataProjects } =
    useGetProjectsQuery({
      pageSize: 1000,
    });
  const buttonOptions = ["Week", "Month"];

  return (
    <div>
      <ButtonGroup
        variant="outlined"
        aria-label="outlined button group"
        sx={{ marginBottom: 2 }}
      >
        {buttonOptions.map((buttonOption) => {
          return (
            <Button
              variant={
                selectedOption === buttonOption ? "contained" : "outlined"
              }
              onClick={() => {
                setSelectedOption(buttonOption);
              }}
            >
              {buttonOption}
            </Button>
          );
        })}
      </ButtonGroup>
      {selectedOption === "Month" && (
        <MonthlyCalendar data={dataProjects?.data} />
      )}
      {selectedOption === "Week" && (
        <WeeklyCalendar data={dataProjects?.data} />
      )}
    </div>
  );
};

export default CalendarPage;
