import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import { useAppDispatch, useAppSelector } from "../hooks/store";
import { clearSession } from "../features/auth/authSlice";
import { currentTitle } from "../app/uiSlice";
import { useCurrentUser } from "../hooks/useCurrentUser";
import { formatCash } from "../utils/helper";
import { useNavigate } from "react-router-dom";

const drawerWidth: number = 240 - 1;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({
  open,
  onToggle,
}: {
  open: boolean;
  onToggle: () => void;
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useCurrentUser();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElMessage, setAnchorElMessage] =
    React.useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    React.useState<null | HTMLElement>(null);
  const pageTitle = useAppSelector(currentTitle);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenMessageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMessage(event.currentTarget);
  };
  const handleCloseMessageMenu = () => {
    setAnchorElMessage(null);
  };
  const handleOpenNotificationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotification(event.currentTarget);
  };
  const handleCloseNotificationMenu = () => {
    setAnchorElNotification(null);
  };
  const handleBalanceMenu = () => {
    navigate("finances");
    setAnchorElUser(null);
  };
  const handleLogout = () => {
    dispatch(clearSession());
    setAnchorElUser(null);
  };

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: "24px", // keep right padding when drawer closed
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={onToggle}
          sx={{
            marginRight: "36px",
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
        >
          {pageTitle}
        </Typography>
        {/* <IconButton color="inherit" onClick={handleOpenNotificationMenu}>
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          color="inherit"
          sx={{ ml: 2 }}
          onClick={handleOpenMessageMenu}
        >
          <Badge badgeContent={0} color="secondary">
            <MessageIcon />
          </Badge>
        </IconButton> */}
        <IconButton color="inherit" sx={{ ml: 2 }} onClick={handleOpenUserMenu}>
          <Avatar
            alt={user?.first_name || ""}
            src={
              !!user?.photo?.length
                ? user?.photo[0].original_url
                : "/no-picture"
            }
          />
        </IconButton>

        {/* User Menu */}
        <Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem key={"finance"} onClick={handleBalanceMenu}>
            <ListItemText>
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Balances {" — "}
                </Typography>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  {user && user?.company && user?.company?.balance_decrypt > 0
                    ? `$${formatCash(user?.company?.balance_decrypt)}`
                    : "$0.00"}
                </Typography>
              </React.Fragment>
            </ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem key={"account"} onClick={handleCloseUserMenu}>
            <ListItemText>My Profile</ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem key={"settings"} onClick={handleCloseUserMenu}>
            <ListItemText>Company Settings</ListItemText>
          </MenuItem>
          <MenuItem key={"logout"} onClick={handleLogout}>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>

        {/* Message Menu */}
        {/*<Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElMessage}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElMessage)}
          onClose={handleCloseMessageMenu}
        >
          <MenuItem key={"msg1"}>
            <ListItemAvatar>
              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Brunch this weekend?"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Ali Connors
                  </Typography>
                  {" — I'll be in your neighborhood doing errands this…"}
                </React.Fragment>
              }
            />
            <Typography variant="body2" color="text.secondary">
              Today
            </Typography>
          </MenuItem>
          <Divider variant="inset" component="li" />
          <MenuItem key={"msg2"}>
            <ListItemAvatar>
              <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Summer BBQ"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    to Scott, Alex, Jennifer
                  </Typography>
                  {" — Wish I could come, but I'm out of town this…"}
                </React.Fragment>
              }
            />
            <Typography variant="body2" color="text.secondary">
              Yesterday
            </Typography>
          </MenuItem>
          <Divider variant="inset" component="li" />
          <MenuItem key={"msg3"}>
            <ListItemAvatar>
              <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
            </ListItemAvatar>
            <ListItemText
              primary="Oui Oui"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Sandra Adams
                  </Typography>
                  {" — Do you have Paris recommendations? Have you ever…"}
                </React.Fragment>
              }
            />
            <Typography variant="body2" color="text.secondary">
              11 Aug
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem key={"viewallmessage"} onClick={handleOpenMessageMenu}>
            <Typography textAlign="center">View all messages</Typography>
          </MenuItem>
        </Menu>
        
        {/* Notification Menu */}
        {/*<Menu
          sx={{ mt: "45px" }}
          id="menu-appbar"
          anchorEl={anchorElNotification}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElNotification)}
          onClose={handleCloseNotificationMenu}
        >
          <MenuItem key={"notif1"}>
            <ListItemText
              primary="Brunch this weekend?"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Ali Connors
                  </Typography>
                  {" — I'll be in your neighborhood doing errands this…"}
                </React.Fragment>
              }
            />
            <Typography variant="body2" color="text.secondary">
              Today
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem key={"notif2"}>
            <ListItemText
              primary="Summer BBQ"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    to Scott, Alex, Jennifer
                  </Typography>
                  {" — Wish I could come, but I'm out of town this…"}
                </React.Fragment>
              }
            />
            <Typography variant="body2" color="text.secondary">
              Yesterday
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem key={"notif3"}>
            <ListItemText
              primary="Oui Oui"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Sandra Adams
                  </Typography>
                  {" — Do you have Paris recommendations? Have you ever…"}
                </React.Fragment>
              }
            />
            <Typography variant="body2" color="text.secondary">
              4 Apr
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem key={"viewallmessage"} onClick={handleCloseNotificationMenu}>
            <Typography textAlign="center">View all notofications</Typography>
          </MenuItem>
            </Menu>*/}
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
