import { GMAP_API_KEY } from "../configs/api";
import http from "../utils/http";

type Point = {
  lat: number;
  lng: number;
};

export function getRadius(lat: number, lng: number, latRadius: number) {
  return getDistanceBetweenPoints(
    { lat: lat - latRadius / 2, lng },
    { lat: lat + latRadius / 2, lng }
  );
}

export function getDistanceBetweenPoints(point1: Point, point2: Point): number {
  const earthRadiusKm = 6371;

  const lat1 = point1.lat;
  const lon1 = point1.lng;
  const lat2 = point2.lat;
  const lon2 = point2.lng;

  let dLat = degToRadians(lat2 - lat1);
  let dLon = degToRadians(lon2 - lon1);

  const rad1 = degToRadians(lat1);
  const rad2 = degToRadians(lat2);

  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rad1) * Math.cos(rad2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return earthRadiusKm * c * 0.62137; //miles
}

function degToRadians(deg: number): number {
  return deg * (Math.PI / 180);
}

export function milesToMeter(miles: number) {
  return Math.round(miles * 1609.344);
}

export const geocode = (address: string) => {
  return http.get(
    // `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&components=country:${countryCode}&key=${GMAP_API_KEY}`
    `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GMAP_API_KEY}`
  );
};
