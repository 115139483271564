import { ProjectReview } from "../types/project";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_REVIEW } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_REVIEW],
});

export const reviewApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getReviews: builder.query<
      QueryResponse<ProjectReview>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ page = 0, pageSize = 100, sortField, sortMode, search }) => ({
        url: `reviews`,
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `created_at|desc`,
          search,
        },
      }),
      providesTags: (result) => {
        // const data = result?.data;
        return [{ type: TAG_TYPE_REVIEW, id: "PARTIAL-LIST" }];
      },
    }),
    getProjectReviews: builder.query<Array<ProjectReview>, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (project_id) => ({
        url: "reviews",
        method: "GET",
        params: {
          sort: "created_at|desc",
          project: project_id,
        },
      }),
      providesTags: (result, _, project_id) => [
        { type: TAG_TYPE_REVIEW, project_id },
      ],
    }),
    getTalentReviews: builder.query<Array<ProjectReview>, number>({
      query: (talent_id) => ({
        url: `api/talents/${talent_id}/review`,
        method: "GET",
        params: {
          sort: "created_at|desc",
          load: "project.company",
        },
      }),
      providesTags: (result, _, talent_id) => [
        { type: TAG_TYPE_REVIEW, talent_id },
      ],
    }),
    submitProjectReview: builder.mutation<
      ProjectReview,
      { project_id: number; resource_id: number; star: number; review?: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ project_id, resource_id, star, review }) => {
        return {
          url: `reviews`,
          method: "POST",
          body: {
            project: project_id,
            resource: resource_id,
            star,
            review,
          },
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_REVIEW, id: payload.project_id },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectReviewsQuery,
  useGetReviewsQuery,
  useGetTalentReviewsQuery,
  useSubmitProjectReviewMutation,
} = reviewApi;
