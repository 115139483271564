export const TAG_TYPE_USER: string = "user";
export const TAG_TYPE_MANAGER: string = "manager";
export const TAG_TYPE_TALENT: string = "talent";
export const TAG_TYPE_CUSTOMER: string = "customer";
export const TAG_TYPE_LOCATION: string = "location";
export const TAG_TYPE_PROJECT: string = "project";
export const TAG_TYPE_PROJECT_TASK: string = "project-task";
export const TAG_TYPE_PROJECT_LOG: string = "project-log";
export const TAG_TYPE_PROJECT_JOB: string = "project-job";
export const TAG_TYPE_COUNTRY: string = "country";
export const TAG_TYPE_VERTICAL: string = "vertical";
export const TAG_TYPE_SKILL: string = "skill";
export const TAG_TYPE_LANGUAGE: string = "language";
export const TAG_TYPE_CERTIFICATION: string = "certification";
export const TAG_TYPE_EDUCATION: string = "education";
export const TAG_TYPE_WORK_EXPERIENCE: string = "work-experience";
export const TAG_TYPE_FINANCE: string = "finance";
export const TAG_TYPE_INVOICE: string = "invoice";
export const TAG_TYPE_REPORT: string = "report";
export const TAG_TYPE_CHAT: string = "chat";
export const TAG_TYPE_REVIEW: string = "chat";
export const TAG_TYPE_FAVORITE_TALENT: string = "favorite-talent";
export const TAG_TYPE_TEMPLATE: string = "template";
export const TAG_TYPE_REASON: string = "reason";
export const TAG_TYPE_SHIFT_TEMPLATE: string = "shift-template";
