import * as React from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridSortModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import NoResultsOverlay from "../../components/NoResultOverlay";
import useDebounce from "../../hooks/useDebounce";
import { Project, ProjectStatus } from "../../types/project";
import { useNavigate } from "react-router-dom";
import { useGetProjectsQuery } from "../../services/project.service";
import { Box, Typography } from "@mui/material";
import { OpenInNew, Person, Place, Refresh } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useCompany } from "../../hooks/useCompany";
import { CompanyStatus } from "../../types/company";
import ModalUploadDocuments from "./ModalUploadDocuments";
import { formatWithTimezone } from "../../utils/dateHelper";
//import  CreateTransform  from "redux-persist/lib/createTransform";

const TableToolbar = ({
  onCreate,
  onRefresh,
  onSearch,
  onShowMap,
}: {
  onSearch: (query?: string) => void;
  onRefresh: () => void;
  onCreate: () => void;
  onShowMap: () => void;
}) => {
  const company = useCompany();
  const [showUploadModal, setShowUploadModal] = React.useState<boolean>(false);
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);
  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack
        style={{ flex: 1 }}
        direction="row"
        justifyContent="flex-end"
        spacing={1}
      >
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Jobs" }}
          sx={{ mr: 2 }}
        />
        {/* <Button variant="contained" onClick={onShowMap}>
          <LocationOnIcon /> Show Map
        </Button> */}
        <Button
          variant="contained"
          onClick={onCreate}
          disabled={company?.status != CompanyStatus.ACTIVE}
        >
          <AddIcon /> Create New Job
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setShowUploadModal(true);
          }}
          disabled={company?.status != CompanyStatus.ACTIVE}
        >
          <AddIcon /> Upload Documents
        </Button>
        <LoadingButton
          size={"large"}
          //variant="outlined"
          onClick={onRefresh}
          sx={{ ml: 1 }}
          endIcon={<Refresh />}
        >
          Refresh
        </LoadingButton>
      </Stack>
      <ModalUploadDocuments
        open={showUploadModal}
        onClose={() => {
          setShowUploadModal(false);
        }}
      />
    </GridToolbarContainer>
  );
};

interface ProjectGridProps {
  onView: (item: any) => void;
  status?: ProjectStatus;
  statuses?: Array<ProjectStatus>;
}

const ProjectGrid = (props: ProjectGridProps) => {
  const { onView, status, statuses } = props;

  const navigate = useNavigate();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const { data, isLoading, isFetching, refetch } = useGetProjectsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : "start_date",
    sortMode: sortModel.length > 0 ? sortModel[0].sort : "desc",
    search: searchQuery,
    status,
    statuses: statuses?.join(","),
  });
  const rows: GridRowsProp | undefined = React.useMemo(() => {
    return data?.data;
  }, [data]);

  const rowCount = React.useMemo(() => {
    return data?.meta.total;
  }, [data]);

  const onBidClick = (row: Project) => {
    navigate(`/jobs/${row?.id}?t=1`);
  };

  const columns: GridColumns = React.useMemo(() => {
    const col: GridColumns = [];
    col.push(
      {
        field: "id",
        headerName: "Job Id",
        hide: true,
      },
      {
        field: "title",
        headerName: "Job Name",
        hide: true,
      },
      {
        field: "client_location",
        headerName: "Location",
        hide: true,
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          !!params.row.customer
            ? params.row.customer?.first_name
            : !!params.row.location
            ? params.row.location.name
            : "?",
      },
      {
        field: "job_title",
        headerName: "Job Title - P.O.#",
        flex: 1,
        minWidth: 300,
        sortable: true,
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Project>) => (
          <Box key={params.row.id}>
            <Stack direction={"row"}>
              <Link
                component="button"
                variant="body2"
                onClick={() => onView(params.row)}
              >
                #{params.row.id} - {params.row.title}
              </Link>
            </Stack>
            {!!params.row.customer ? (
              <Typography variant="body2">
                <Person fontSize="inherit" color="disabled" />{" "}
                {params.row.customer?.first_name}
              </Typography>
            ) : !!params.row.location ? (
              <Typography variant="body2">
                <Place fontSize="inherit" color="disabled" />{" "}
                {params.row.location.name}
              </Typography>
            ) : (
              <Typography>?</Typography>
            )}
          </Box>
        ),
        disableExport: true,
      },
      {
        field: "start_date",
        headerName: "Start At",
        headerAlign: "center",
        minWidth: 220,
        sortingOrder: ["desc", "asc", null],
        sortable: true,
        filterable: true,
        align: "left",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          `${formatWithTimezone(params.row.start_date, params.row.timezone)}`,
      },
      {
        field: "manager_id",
        headerName: "Manager",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          !!params.row.manager
            ? `${params.row.manager.first_name} ${params.row.manager.last_name}`
            : `-`,
      }
    );

    if (status == ProjectStatus.CANCELED) {
      col.push({
        field: "canceled_reason",
        headerName: "Cancel Reason",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: false,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Project>) =>
          params.row.canceled_reason,
      });
    }

    //last one is for control
    col.push({
      field: "actions",
      headerName: "Details",
      headerAlign: "center",
      minWidth: 100,
      type: "actions",
      align: "center",
      width: 50,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          key={params.row.id}
          icon={<OpenInNew />}
          onClick={() => navigate(`/jobs/${params.row.id}`)}
          label="Open Detail"
        />,
      ],
      disableExport: true,
    });
    return col;
  }, [status]);

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    navigate(`/jobs/create`);
  }, []);

  const handleShowMap = React.useCallback(() => {
    navigate(`/jobs/map`);
  }, []);

  const CustomToolbar = React.useMemo(
    () => () =>
      (
        <TableToolbar
          onSearch={handleSearch}
          onCreate={handleCreate}
          onShowMap={handleShowMap}
          onRefresh={refetch}
        />
      ),
    [handleSearch]
  );

  return (
    <Paper style={{ height: "80vh" }}>
      <DataGrid
        rows={rows || []}
        columns={columns}
        paginationMode="server"
        rowCount={rowCount}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        pageSize={pageSize}
        rowsPerPageOptions={[100]}
        onPageSizeChange={(newSize) => setPageSize(newSize)}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        loading={isLoading || isFetching}
        components={{
          LoadingOverlay: LinearProgress,
          NoResultsOverlay: NoResultsOverlay,
          Toolbar: CustomToolbar,
        }}
        disableSelectionOnClick
        disableColumnFilter
        // @ts-ignore
        getRowHeight={() => 56}
        sx={{
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#f5f5f5",
            borderTop: "1px solid #ddd",
          },
        }}
      />
    </Paper>
  );
};
export default ProjectGrid;
