import * as React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useGetTemplatesQuery } from "../services/template.service";
import { Template } from "../types/template";

const SelectTemplate = ({
  onChange,
  sx,
  value,
  label = "Template",
  error,
  helperText,
}: {
  onChange: (arg?: number) => void;
  sx?: any;
  value: number;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
}) => {
  const {
    data: templates,
    isLoading,
    isFetching,
  } = useGetTemplatesQuery({
    load: "customer,location",
  });

  const selected = React.useMemo(
    () => templates?.data?.find((it) => it.id == value),
    [templates, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={templates?.data || []}
      value={selected || null}
      getOptionLabel={(option) =>
        `${option.title} [${
          !!option.customer ? option.customer.first_name : option.location.name
        }]`
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search template..."
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue: Template | null) => {
        onChange(newValue?.id);
      }}
      loading={isLoading || isFetching}
    />
  );
};
export default SelectTemplate;
