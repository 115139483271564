import PinDrop from "@mui/icons-material/PinDrop";
import { Stack, Typography } from "@mui/material";
import * as React from "react";

const CoordinateInfo = ({ lat, lng }: { lat?: number; lng?: number }) => {
  if (!lat || !lng) return null;
  return (
    <Stack direction={"row"} alignItems="center" sx={{ mt: 2 }}>
      <PinDrop sx={{ color: "#999", fontSize: 18, mr: 1 }} />
      <Typography variant="caption">
        {lat},{lng}{" "}
        <a
          href={`https://maps.google.com/maps?q=${lat},${lng}&z=8`}
          target="_blank"
        >
          Open In Map
        </a>
      </Typography>
    </Stack>
  );
};
export default React.memo(CoordinateInfo);
