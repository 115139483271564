import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
  Typography,
  colors,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SelectSimple from "../../components/SelectSimple";
import SelectTimezone from "../../components/SelectTimezone";
import { Shift, ShiftTask } from "../../types/shift";
import ModalShiftTaskForm from "./ModalShiftTaskForm";
import Add from "@mui/icons-material/Add";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { Project } from "../../types/project";

interface ModalShiftFormProps {
  open: boolean;
  onClose: () => void;
  data: Shift | null;
  project?: Project;
  onAdd?: (shift: Shift) => void;
}

const repeatOptions = [
  { value: "never", label: "Never" },
  { value: "this-week", label: "This Week" },
  { value: "weekly", label: "Weekly" },
  { value: "2week", label: "Every 2 Week" },
  { value: "3week", label: "Every 3 Week" },
  { value: "4week", label: "Every 4 Week" },
  { value: "5week", label: "Every 5 Week" },
];

const dayOptions = [
  { value: "day_monday", label: "Mon" },
  { value: "day_tuesday", label: "Tue" },
  { value: "day_wednesday", label: "Wed" },
  { value: "day_thursday", label: "Thu" },
  { value: "day_friday", label: "Fri" },
  { value: "day_saturday", label: "Sat" },
  { value: "day_sunday", label: "Sun" },
];

const ModalShiftForm = ({
  open,
  onClose,
  data,
  project,
  onAdd,
}: ModalShiftFormProps) => {
  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
    watch,
    setError,
  } = useForm();

  const [tasks, setTasks] = React.useState<Array<ShiftTask>>([]);
  const [taskModal, setTaskModal] = React.useState<{
    open: boolean;
    data: ShiftTask | null;
  }>({
    open: false,
    data: null,
  });

  const handleAddTask = (task: ShiftTask) => {
    task.id = Date.now();
    setTaskModal({ open: false, data: null });
    setTasks([...tasks, task]);
  };
  const handleDeleteTask = (task: ShiftTask) => {
    const newTasks = tasks.filter((it) => it.id != task.id);
    setTasks(newTasks);
  };

  const onSubmit = async (payload: any) => {
    if (!data) {
      if (onAdd) onAdd(payload);
      return;
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("name", data?.name);
      setValue("description", data?.description);
      setValue("start_time", data?.start_time);
      setValue("end_time", data?.end_time);
      setValue("repeat", data?.repeat || "never");
      setValue("day_monday", data?.day_monday || false);
      setValue("day_tuesday", data?.day_tuesday || false);
      setValue("day_wednesday", data?.day_wednesday || false);
      setValue("day_thursday", data?.day_thursday || false);
      setValue("day_friday", data?.day_friday || false);
      setValue("day_saturday", data?.day_saturday || false);
      setValue("day_sunday", data?.day_sunday || false);
      setValue("timezone", project?.timezone);
      setValue("start_date", project?.start_date);
      setValue("end_date", data?.end_date);
      setTasks([]);
      clearErrors();
    }
  }, [data, project, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Shift
        </DialogTitle>
        <DialogContent>
          <SelectTimezone
            onChange={() => {}}
            value={project?.timezone || ""}
            disabled={true}
            label="Job's Timezone"
          />
          <Controller
            name="start_date"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DesktopDatePicker
                sx={{ width: "100%" }}
                onChange={onChange}
                value={dayjs(value)}
                format="ddd, MMM D, YYYY"
                label="Start Date"
              />
            )}
          />
          <Stack direction={"row"}>
            <Controller
              name="start_time"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DesktopTimePicker
                  sx={{ mr: 1, width: "100%" }}
                  onChange={onChange}
                  value={!!value ? dayjs(value) : null}
                  format="hh:mm A"
                  label="Start Shift"
                  slotProps={{
                    textField: {
                      helperText: !!errors.end_time && (
                        <Typography variant="caption" color={"error"}>
                          Please set a valid value
                        </Typography>
                      ),
                    },
                  }}
                />
              )}
            />
            <Controller
              name="end_time"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <DesktopTimePicker
                  sx={{ width: "100%" }}
                  onChange={onChange}
                  value={!!value ? dayjs(value) : null}
                  format="hh:mm A"
                  label="End Shift"
                  slotProps={{
                    textField: {
                      helperText: !!errors.end_time && (
                        <Typography variant="caption" color={"error"}>
                          Please set a valid value
                        </Typography>
                      ),
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Controller
            name="repeat"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value, ref } }) => (
              <SelectSimple
                title="Repeat"
                data={repeatOptions}
                value={value}
                sx={{ mt: 1 }}
                onChange={onChange}
              />
            )}
          />
          {watch("repeat") != "never" && (
            <>
              <Stack direction={"row"} sx={{ mt: 1 }}>
                {dayOptions.map((option: any) => {
                  return (
                    <Paper
                      elevation={0}
                      variant="outlined"
                      sx={{ mr: 1 }}
                      key={option.value}
                    >
                      <Controller
                        name={option.value}
                        control={control}
                        render={({ field }) => {
                          return (
                            <FormControlLabel
                              control={<Checkbox {...field} />}
                              label={option.label}
                              labelPlacement="bottom"
                              sx={{ width: 30 }}
                              slotProps={{ typography: { fontSize: 12 } }}
                            />
                          );
                        }}
                      />
                    </Paper>
                  );
                })}
              </Stack>
              <Stack direction={"row"}>
                <Controller
                  name="start_date"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DesktopDatePicker
                      sx={{ width: "100%", mr: 1 }}
                      onChange={onChange}
                      value={!!value ? dayjs(value) : undefined}
                      disabled={true}
                      format="ddd, MMM D, YYYY"
                      label="Start Date"
                    />
                  )}
                />
                <Controller
                  name="end_date"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <DesktopDatePicker
                      sx={{ width: "100%" }}
                      onChange={onChange}
                      value={!!value ? dayjs(value) : undefined}
                      format="ddd, MMM D, YYYY"
                      label="End Date"
                    />
                  )}
                />
              </Stack>
            </>
          )}
          <Divider sx={{ mt: 1, mb: 2 }} />
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Shift Name"
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
                helperText={!!errors.name && "This field is required"}
              />
            )}
          />
          <Controller
            name="num_of_talent"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Number of talent required"
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
                helperText={!!errors.name && "This field is required"}
              />
            )}
          />
          <Button
            variant="outlined"
            sx={{ mt: 2 }}
            onClick={() => setTaskModal({ open: true, data: null })}
            startIcon={<Add />}
          >
            Add Shift Task
          </Button>
          {tasks.length > 0 && (
            <List>
              {tasks.map((it, idx) => (
                <ListItem
                  sx={{ backgroundColor: "#f5f5f5", px: 2 }}
                  divider
                  secondaryAction={
                    <IconButton edge="end" onClick={() => handleDeleteTask(it)}>
                      <DeleteOutline />
                    </IconButton>
                  }
                >
                  <ListItemText
                    key={idx}
                    primary={it.name}
                    secondary={it.description}
                    secondaryTypographyProps={{ fontSize: 11 }}
                  />
                </ListItem>
              ))}
            </List>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={false}>
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
        <ModalShiftTaskForm
          open={taskModal.open}
          onClose={() => setTaskModal({ open: false, data: null })}
          data={taskModal.data}
          onAdd={handleAddTask}
        />
      </form>
    </Dialog>
  );
};
export default ModalShiftForm;
