import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import StarIcon from "@mui/icons-material/Star";
import { useNavigate } from "react-router-dom";
import { Project, ProjectResource } from "../../types/project";
import { Talent } from "../../types/users";
import { nl2br } from "../../utils/helper";
import { useGetTalentByIdQuery } from "../../services/talent.service";
import { formatMoney } from "../../utils/helper";
import { getDistanceBetweenPoints } from "../../utils/mapHelper";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  marginTop: 40,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface ProjectResourceDrawerProps {
  data: ProjectResource | null;
  dataProject: Project;
  forceRefresh?: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProjectResourceDrawer(
  props: ProjectResourceDrawerProps
) {
  const { data, dataProject, forceRefresh = false, open, setOpen } = props;
  const navigate = useNavigate();

  const projectCoordinate = React.useMemo(() => {
    console.log("dataProject", dataProject);
    let _coordinates: number[] = [];
    if (!!dataProject?.customer) {
      _coordinates = dataProject?.customer?.coordinate?.coordinates;
    } else if (!!dataProject?.location) {
      _coordinates = dataProject?.location?.coordinate?.coordinates || [];
    }
    if (_coordinates.length > 0) {
      return { lat: _coordinates[1], lng: _coordinates[0] };
    } else return undefined;
  }, [dataProject]);

  console.log("projectCoordinate", projectCoordinate);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setOpen(open);
    };

  const list = () => (
    <Box sx={{ width: 450, mb: 8 }} role="presentation">
      <DrawerHeader>
        <ClearIcon
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        />
      </DrawerHeader>
      <Stack direction={"row"} sx={{ p: 2 }} alignItems="center">
        <Avatar
          alt={`${data?.resource?.first_name}`}
          src={`${
            data?.resource?.photo?.[0]?.original_url || "broken-image.jpg"
          }`}
          sx={{ width: 100, height: 100 }}
        />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h6">{data?.resource?.full_name}</Typography>
          <Typography variant="body2">
            {data?.resource?.city}, {data?.resource?.state}{" "}
            {data?.resource?.zip}
          </Typography>
          <Rating
            value={data?.resource?.total_rating || 0}
            readOnly
            precision={0.5}
          />
        </Box>
      </Stack>
      <Divider />

      <List>
        <ListItem>
          <ListItemText
            primary="Rate"
            secondary={`$${formatMoney(+Number(data?.rate || 0))}`}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary="Max Hour"
            secondary={`${data?.max_hour || 0} hours`}
          />
        </ListItem>
        <Divider />
        {/* <ListItem>
          <ListItemText
            primary="Distance From Site"
            secondary={
              !!data?.resource?.coordinate
                ? `${+Number(
                    getDistanceBetweenPoints(
                      {
                        lat: data?.resource?.coordinate?.coordinates[1] || 0,
                        lng: data?.resource?.coordinate?.coordinates[0] || 0,
                      },
                      !!projectCoordinate
                        ? projectCoordinate
                        : { lat: 0, lng: 0 }
                    )
                  ).toFixed(2)} miles`
                : `n/a`
            }
          />
        </ListItem> */}
        <Divider />
      </List>
      <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
        <Button
          variant="contained"
          onClick={() => navigate(`/talents/${data?.resource_id}`)}
        >
          More Detail
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        {/*<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
