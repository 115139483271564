import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Project, ProjectStatus, ProjectTask } from "../../types/project";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAddProjectMutation,
  useEditProjectMutation,
  useGetProjectByIdQuery,
} from "../../services/project.service";
import { useTitle } from "../../hooks/useTitle";
import { Controller, useForm } from "react-hook-form";
import useScroll from "../../hooks/useScroll";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import SelectLocation from "../../components/SelectLocation";
import SelectSkills from "../../components/SelectSkills";
import SelectManager from "../../components/SelectManager";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
// import SelectCertifications from "../../components/SelectCertification";
import ModalTaskForm from "./ModalTaskForm";
import StickyBox from "react-sticky-box";
import { Add, AlarmAddOutlined, Delete } from "@mui/icons-material";
import dayjs from "dayjs";

import LocationModalForm from "../location/LocationModalForm";
import { Location } from "../../types/location";
// import { Customer } from "../../types/customer";
import SelectTemplate from "../../components/SelectTemplate";
import { useGetTemplateByIdQuery } from "../../services/template.service";
import SelectTimezone from "../../components/SelectTimezone";
import {
  useAddProjectTaskMutation,
  useDeleteProjectTaskMutation,
} from "../../services/projectTask.service";

const myTimezone = dayjs.tz.guess();

const ProjectEditPage = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [scrollToBasic, basicRef] = useScroll();
  const [scrollToLocation, locationRef] = useScroll();
  const [scrollToSkill, skillRef] = useScroll();
  const [scrollToContact, contactRef] = useScroll();
  const [scrollToTask, taskRef] = useScroll();

  const [useTemplate, setUseTemplate] = React.useState(false);
  const [selectedTemplate, setSelectedTemplate] = React.useState(0);

  const [taskModal, setTaskModal] = React.useState<{
    open: boolean;
    data: ProjectTask | null;
  }>({
    open: false,
    data: null,
  });
  const [newTasks, setNewTasks] = React.useState<Array<ProjectTask>>([]);
  const [locationModal, setLocationModal] = React.useState<{
    open: boolean;
    data: Location | null;
  }>({
    open: false,
    data: null,
  });
  const [data, setData] = React.useState<Partial<Project> | undefined>(
    undefined
  );
  const { data: job, isLoading } = useGetProjectByIdQuery(
    parseInt(projectId!),
    {
      skip: !projectId,
    }
  );
  const {
    data: template,
    isLoading: loadingTemplate,
    isFetching: fetchingTemplate,
  } = useGetTemplateByIdQuery(selectedTemplate, {
    skip: !selectedTemplate,
  });
  const [add, { isLoading: adding }] = useAddProjectMutation();
  const [edit, { isLoading: updating }] = useEditProjectMutation();
  const [target, setTarget] = React.useState("location");
  const [addTask, { isLoading: addingTask }] = useAddProjectTaskMutation();
  const [deleteTask, { isLoading: deletingTask }] =
    useDeleteProjectTaskMutation();

  const isEdit = React.useMemo(() => !!projectId, [projectId]);
  useTitle(!!isEdit ? "Edit Job" : "Create Job");

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    register,
    unregister,
    watch,
  } = useForm();
  const watchType = watch("type");
  React.useEffect(() => {
    if (watchType === "hourly") {
      register("hourly_rate");
      unregister("total_pay");
    } else {
      register("total_pay");
      unregister("hourly_rate");
    }
  }, [register, unregister, watchType]);

  const onSubmit = async (payload: any) => {
    try {
      if (target == "customer" && !payload.customer) {
        setError("customer", { type: "custom", message: "Invalid" });
        return;
      } else if (target == "location" && !payload.location) {
        setError("location", { type: "custom", message: "Invalid" });
        return;
      }
      if (target == "customer") payload.location = undefined;
      else payload.customer = undefined;

      payload.start_date = dayjs(payload.start_date).format("YYYY-MM-DD HH:mm");

      if (!job) {
        const project: Project = await add(payload).unwrap();

        if (newTasks.length > 0) {
          let promises = newTasks
            .filter((it) => it._internal_op == "add")
            .map(
              async (it) =>
                await addTask({
                  name: it.name,
                  project: Number(project.id),
                  description: it.description || undefined,
                  start_date: it.start_date || undefined,
                })
            );
          await Promise.all(promises);
        }
        toast.success("Job created");
        navigate(`/jobs/${project.id}`, { replace: true });
        //
      } else {
        if (!payload.password) delete payload.password;
        payload.id = job.id;
        console.log("payload", payload);

        await edit(payload).unwrap();

        if (newTasks.length > 0) {
          let promises = newTasks.map(async (it) => {
            if (it._internal_op == "add") {
              return await addTask({
                name: it.name,
                project: Number(projectId),
                description: it.description || undefined,
                start_date: it.start_date || undefined,
              });
            } else if (it._internal_op == "delete") {
              return await deleteTask({
                id: it.id,
                project: Number(projectId),
              });
            }
          });
          await Promise.all(promises);
        }

        toast.success("Job updated");
        navigate(`/jobs/${job.id}`, { replace: true });
      }
    } catch (err: any) {
      toast.error(err);
    }
  };
  // ProjectStatus.DRAFT,
  // ProjectStatus.PUBLISHED,
  // ProjectStatus.IN_PROGRESS,
  const StickyMenu = React.useCallback(
    () => (
      <StickyBox offsetTop={70} offsetBottom={20}>
        {job?.status === ProjectStatus.SCHEDULED ||
        job?.status === ProjectStatus.IN_PROGRESS ? (
          <List>
            <ListItem>
              <ListItemButton onClick={scrollToContact as any}>
                <ListItemText>Select a Manager</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItemText
              primary="Go to:"
              primaryTypographyProps={{ fontSize: 20, color: "primary.main" }}
              //secondaryTypographyProps={{ fontSize: 15, color: 'green',}}
            />
            <ListItem>
              <ListItemButton onClick={scrollToBasic as any}>
                <ListItemText>Job Information</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={scrollToSkill as any}>
                <ListItemText>Select/Search Skills</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={scrollToContact as any}>
                <ListItemText>Select a Manager</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={scrollToTask as any}>
                <ListItemText>Add Tasks (if needed)</ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </StickyBox>
    ),
    []
  );

  const handleAddTask = (task: ProjectTask) => {
    task.id = Date.now();
    task._internal_op = "add";
    setTaskModal({ open: false, data: null });
    setNewTasks([...newTasks, task]);
  };
  const handleDeleteTask = (task: ProjectTask) => {
    const newList = newTasks
      .filter((it) => it.id != task.id || it._internal_op != "add")
      .map((it) => {
        if (it.id == task.id) {
          return {
            ...it,
            _internal_op: "delete" as any,
          };
        }
        return it;
      });
    setNewTasks(newList);
  };

  React.useEffect(() => {
    if (!!template) setData(template);
    else setData(job);
  }, [job, template]);

  React.useEffect(() => {
    setValue("title", data?.title || "");
    setValue("description", data?.description || "");
    setValue("start_date", data?.start_date || null);
    setValue("confidential_information", data?.confidential_information || "");
    setValue("is_private", 1);
    setValue("approximate_hours_to_complete", "");
    setValue("location", data?.location_id);
    setValue("manager", data?.manager_id);
    setValue("customer", data?.customer_id);

    if (!!data?.customer_id) {
      setTarget("customer");
    } else {
      // default on init
      setTarget("location");
    }

    const skillIds = data?.skills?.map((it) => it.id);
    setValue("skills", skillIds || []);

    const certIds = data?.certifications?.map((it) => it.id);
    setValue("certifications", certIds || []);

    setNewTasks(data?.tasks || []);

    setValue("is_photo_required", !!data?.is_photo_required || false);
    setValue("tool_required", !!data?.tool_required || false);
    setValue("mile_radius", 0);
    setValue("type", "fixed");
    setValue("hourly_rate", data?.hourly_rate || null);
    setValue("total_pay", data?.total_pay || 0);
    setValue("num_of_talent", data?.num_of_talent || 1);
    // setValue("internal_notes", data?.internal_notes);
    setValue("timezone", data?.timezone || myTimezone);
  }, [data]);

  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item lg={3}>
          <StickyMenu />
        </Grid>
        <Grid item lg={9}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              {!isEdit && (
                <Card sx={{ mt: 0 }}>
                  <CardHeader
                    titleTypographyProps={{
                      fontSize: 18,
                      color: "#07bc0c",
                    }}
                    title="Job Template"
                  />
                  <CardContent>
                    <Stack>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={useTemplate}
                            onChange={(e) => setUseTemplate(e.target.checked)}
                          />
                        }
                        label="If you have a template created, please Click the Checkbox and Select an option."
                      />
                    </Stack>
                    {useTemplate && (
                      <SelectTemplate
                        label="Select Template"
                        onChange={(id) => {
                          if (!!id) setSelectedTemplate(id);
                        }}
                        value={selectedTemplate}
                        sx={{ mt: 1 }}
                        error={!!errors.customer}
                        helperText={
                          !!errors.customer && "This field is required"
                        }
                      />
                    )}
                    {(loadingTemplate || fetchingTemplate) && (
                      <Box>
                        <Typography variant="caption">
                          Loading template...
                        </Typography>
                      </Box>
                    )}
                  </CardContent>
                </Card>
              )}
              {job?.status === ProjectStatus.SCHEDULED ||
              job?.status === ProjectStatus.IN_PROGRESS ? (
                <>
                  <Card sx={{ mt: 1 }} ref={contactRef}>
                    <CardHeader
                      titleTypographyProps={{ fontSize: 18, color: "#07bc0c" }}
                      title="Assign the Project Manager for this job"
                    />
                    <CardContent>
                      <Box>
                        <Controller
                          name="manager"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <SelectManager
                              label="Select a Project Manager for this specific job."
                              onChange={onChange}
                              value={value}
                              error={!!errors.manager}
                              helperText={
                                !!errors.manager && "This field is required"
                              }
                            />
                          )}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </>
              ) : (
                <>
                  <Card sx={{ mt: 1 }} ref={basicRef}>
                    <CardHeader
                      titleTypographyProps={{ fontSize: 18, color: "#07bc0c" }}
                      title="Job Information"
                    />
                    <CardContent>
                      <Controller
                        name="title"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            InputLabelProps={{ shrink: !!value }}
                            label="Job Title - P.O.#"
                            value={value}
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                            error={!!errors.title}
                            helperText={
                              !!errors.title && "This field is required"
                            }
                          />
                        )}
                      />
                      <Card sx={{ mt: 1 }} ref={locationRef}>
                        <CardHeader
                          titleTypographyProps={{
                            fontSize: 18,
                            color: "#07bc0c",
                          }}
                          title="Where is the Work Site?"
                        />
                        <CardContent>
                          {target === "location" && (
                            <>
                              <Controller
                                name="location"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                  <SelectLocation
                                    label="Select a saved Location"
                                    onChange={onChange}
                                    value={value}
                                    sx={{ mt: 0 }}
                                    error={!!errors.location}
                                    helperText={
                                      !!errors.location &&
                                      "This field is required"
                                    }
                                  />
                                )}
                              />
                              <Button
                                variant="text"
                                onClick={() =>
                                  setLocationModal({ open: true, data: null })
                                }
                              >
                                + Add New Location
                              </Button>
                            </>
                          )}
                          {target == "new" && (
                            <Controller
                              name="address"
                              control={control}
                              rules={{ required: true }}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <TextField
                                  //InputLabelProps={{ shrink: !!value }}
                                  InputLabelProps={{ style: { fontSize: 14 } }}
                                  //InputProps={{ style: {fontSize: 18}}}
                                  label="Address"
                                  value={value}
                                  fullWidth
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  sx={{ mt: 0 }}
                                  error={!!errors.address}
                                  helperText={
                                    !!errors.address && "This field is required"
                                  }
                                />
                              )}
                            />
                          )}
                        </CardContent>
                      </Card>
                      <Card sx={{ mt: 1 }}>
                        <CardHeader
                          titleTypographyProps={{
                            fontSize: 18,
                            color: "#07bc0c",
                          }}
                          title="Please enter Date and Time of the job according to selected time zone."
                        />
                        <CardContent>
                          <Controller
                            name="start_date"
                            control={control}
                            rules={{ required: true }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <DesktopDateTimePicker
                                sx={{ mt: 1, width: "50%" }}
                                onChange={onChange}
                                value={dayjs(value)}
                                label="Start Managing Time"
                                format="MM/DD/YYYY hh:mm A"
                                onError={() => {
                                  setError("start_date", {
                                    type: "custom",
                                    message: "Invalid",
                                  });
                                }}
                                onAccept={() => clearErrors("start_date")}
                                slotProps={{
                                  textField: {
                                    helperText:
                                      !!errors.start_date &&
                                      "Please set a valid date and time",
                                  },
                                }}
                                // :`NOTE: JOB TIME WILL BE ADJUSTED ACCORDING TO ITS' LOCATION TIME ZONE.`
                              />
                            )}
                          />
                          <Controller
                            name="timezone"
                            control={control}
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <SelectTimezone
                                sx={{ width: "50%" }}
                                label="Select timezone of the job"
                                onChange={onChange}
                                value={value}
                                error={!!errors.timezone}
                                helperText={
                                  !!errors.timezone && "This field is required"
                                }
                              />
                            )}
                          />
                        </CardContent>
                      </Card>
                      <Card sx={{ mt: 1 }}>
                        <CardHeader
                          titleTypographyProps={{
                            fontSize: 18,
                            color: "#07bc0c",
                          }}
                          title="Enter SOW"
                        />
                        <CardContent>
                          <Controller
                            name="description"
                            control={control}
                            defaultValue=""
                            rules={{ required: true }}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <TextField
                                sx={{ mt: 2 }}
                                //InputLabelProps={{ shrink: !!value }}
                                InputLabelProps={{ style: { fontSize: 14 } }}
                                //InputProps={{style : { fontSize:14}}}
                                label="SOW – add the Description/Scope of Work"
                                multiline={true}
                                minRows={10}
                                value={value}
                                fullWidth
                                onChange={onChange}
                                onBlur={onBlur}
                                error={!!errors.description}
                                helperText={
                                  !!errors.description &&
                                  "This field is required"
                                }
                              />
                            )}
                          />
                        </CardContent>
                      </Card>
                      <Controller
                        name="confidential_information"
                        control={control}
                        defaultValue=""
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            InputLabelProps={{ style: { fontSize: 14 } }}
                            //InputProps={{ style: {fontSize: 14}}}
                            label="Enter any Confidential Information - Note: This will only be seen once the Talent is hired by the Customer. (optional)"
                            multiline={true}
                            minRows={2}
                            value={value}
                            fullWidth
                            onChange={onChange}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <Controller
                        name="num_of_talent"
                        control={control}
                        defaultValue=""
                        rules={{ required: true }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <TextField
                            InputLabelProps={{ shrink: !!value }}
                            label="How many Talents do you need? Please select at least one"
                            value={value}
                            disabled={false}
                            fullWidth
                            type={"number"}
                            onChange={onChange}
                            onBlur={onBlur}
                            error={!!errors.num_of_talent}
                            helperText={
                              !!errors.num_of_talent && "This field is required"
                            }
                          />
                        )}
                      />
                    </CardContent>
                  </Card>
                  <Card sx={{ mt: 1 }} ref={skillRef}>
                    <CardHeader
                      titleTypographyProps={{ fontSize: 18, color: "#07bc0c" }}
                      title="What are the required Skills you are looking for?"
                    />
                    <CardContent>
                      <Controller
                        name="skills"
                        control={control}
                        // rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <SelectSkills
                            label="Select/Search Skills"
                            onChange={onChange}
                            values={value}
                            error={!!errors.skills}
                            helperText={
                              !!errors.skills && "This field is required"
                            }
                          />
                        )}
                      />
                    </CardContent>
                  </Card>
                  <Card sx={{ mt: 1 }} ref={contactRef}>
                    <CardHeader
                      titleTypographyProps={{ fontSize: 18, color: "#07bc0c" }}
                      title="Assign the Project Manager for this job"
                    />
                    <CardContent>
                      <Box>
                        <Controller
                          name="manager"
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <SelectManager
                              label="Select a Project Manager for this specific job."
                              onChange={onChange}
                              value={value}
                              error={!!errors.manager}
                              helperText={
                                !!errors.manager && "This field is required"
                              }
                            />
                          )}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                  <Card sx={{ mt: 1 }} ref={taskRef}>
                    <CardHeader
                      titleTypographyProps={{ fontSize: 18, color: "#07bc0c" }}
                      title="Tasks - Note: this is just in case you need them to do a special assigment (optional)"
                      action={
                        <Button
                          // variant="outlined"
                          onClick={() =>
                            setTaskModal({ open: true, data: null })
                          }
                          startIcon={<Add />}
                        >
                          Add Task
                        </Button>
                      }
                    />
                    <CardContent>
                      <List>
                        {newTasks
                          .filter((it) => it._internal_op != "delete")
                          .map((it, idx) => (
                            <ListItem
                              sx={{ backgroundColor: "#f5f5f5", mt: 1, p: 2 }}
                              secondaryAction={
                                <IconButton
                                  edge="end"
                                  onClick={() => handleDeleteTask(it)}
                                >
                                  <Delete />
                                </IconButton>
                              }
                            >
                              <ListItemText
                                key={idx}
                                primary={it.name}
                                secondary={it.description}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </CardContent>
                  </Card>
                </>
              )}

              <Stack direction="row" justifyContent={"flex-end"} sx={{ mt: 2 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  size="large"
                  disabled={isLoading}
                  loading={adding || updating}
                  // sx={{ width: "50%" }}
                >
                  {isEdit ? "Update" : "Create"}
                </LoadingButton>
              </Stack>
            </Box>
          </form>
        </Grid>
      </Grid>
      <ModalTaskForm
        open={taskModal.open}
        onClose={() => setTaskModal({ open: false, data: null })}
        data={taskModal.data}
        onAdd={handleAddTask}
      />
      <LocationModalForm
        open={locationModal.open}
        onClose={() => setLocationModal({ open: false, data: null })}
        onCreated={(newId) => setValue("location", newId)}
        data={locationModal.data}
      />
    </Box>
  );
};
export default ProjectEditPage;
