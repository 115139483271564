import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import { ShiftTask } from "../../types/shift";

interface ModalShiftTaskFormProps {
  open: boolean;
  onClose: () => void;
  data: ShiftTask | null;
  onAdd?: (task: ShiftTask) => void;
}

const ModalShiftTaskForm = ({
  open,
  onClose,
  data,
  onAdd,
}: ModalShiftTaskFormProps) => {
  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (payload: any) => {
    if (!data) {
      payload.id = Date.now();
      if (onAdd) onAdd(payload);
      return;
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("name", data?.name);
      setValue("description", data?.description);
      clearErrors();
    }
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Shift Task
        </DialogTitle>
        <DialogContent>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Task Name"
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.name}
                helperText={!!errors.name && "This field is required"}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Task Description (optional)"
                value={value}
                fullWidth
                minRows={5}
                multiline={true}
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.description}
                helperText={!!errors.description && "This field is required"}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={false}>
            {!data ? "Add" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalShiftTaskForm;
