import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";

import { Controller, useForm } from "react-hook-form";
import { Project, ProjectResource } from "../../types/project";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { useCancelProjectMutation } from "../../services/project.service";
import SelectReason from "../../components/SelectReason";

interface ModalCancelProjectProps {
  open: boolean;
  onClose: () => void;
  project: Project | null;
}

const ModalCancelProject = ({
  open,
  onClose,
  project,
}: ModalCancelProjectProps) => {
  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [cancel, { isLoading }] = useCancelProjectMutation();

  const onSubmit = async (payload: any) => {
    try {
      if (!project) return;

      await cancel({ projectId: project.id, reason: payload.reason }).unwrap();
      toast.success("Project canceled!");
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("reason", "");
      clearErrors();
    }
  }, [project, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">Cancel Project</DialogTitle>
        <DialogContent>
          <Controller
            name="reason"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SelectReason
                label="Select Cancel Reason"
                onChange={onChange}
                error={!!errors.reason}
                helperText={!!errors.reason && "This field is required"}
                type="cancel-job"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalCancelProject;
