import { Chat, ChatMessage } from "../types/chat";
// import { File } from "../types/common";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_CHAT } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_CHAT],
});

export const chatApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getChat: builder.query<Array<Chat>, number>({
      query: (project_id) => {
        return {
          url: `api/user/chats/${project_id}/project`,
          method: "GET",
          params: {
            sort: "created_at|desc",
            load: "participants,participants.user",
          },
        };
      },
    }),
    getChatMessages: builder.query<
      QueryResponse<ChatMessage>,
      { projectId: number; chatId: number; page?: number; pageSize?: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ page = 1, pageSize = 100, projectId, chatId }) => {
        return {
          url: `messages/${projectId}/${chatId}/chat`,
          method: "GET",
          params: {
            page,
            per_page: pageSize,
            sort: "created_at|desc",
            load: "documents,user",
          },
        };
      },
      providesTags: (result, err, { chatId }) => [
        { type: TAG_TYPE_CHAT, id: chatId },
      ],
    }),
    // DISABLE DEBUGGER NETWORK-INSPECT WHEN TESTING UPLOAD!!!
    sendChatMessage: builder.mutation<
      ChatMessage,
      {
        chat_id: number;
        project_id: number;
        body: string;
        file?: File;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ chat_id, project_id, body, file }) => {
        let formData = new FormData();
        formData.append("chat_id", chat_id.toString());
        formData.append("body", body);
        if (!!file) formData.append("document", file as any);
        return {
          url: `messages/${project_id}/${chat_id}/chat`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, { chat_id }) => [
        { type: TAG_TYPE_CHAT, id: chat_id },
      ],
    }),
    updateLastRead: builder.mutation<void, number>({
      query: (chat_id) => {
        return {
          url: `api/user/messages/${chat_id}/read`,
          method: "POST",
        };
      },
    }),
    joinChat: builder.mutation<
      Chat,
      { resource_id: number; project_id: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ resource_id, project_id }) => {
        return {
          url: `chats/${project_id}/project`,
          method: "POST",
          body: {
            resource: resource_id,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  // useGetChatQuery,
  useGetChatMessagesQuery,
  useSendChatMessageMutation,
  useJoinChatMutation,
} = chatApi;
