import * as React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { useGetManagersQuery } from "../services/manager.service";
import { Manager } from "../types/users";

const SelectManager = ({
  onChange,
  sx,
  value,
  label = "Manager",
  error,
  helperText,
}: {
  onChange: (arg?: number) => void;
  sx?: any;
  value: number;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
}) => {
  const {
    data: managers,
    isLoading,
    isFetching,
  } = useGetManagersQuery({
    page: 0,
    pageSize: 100,
    sortField: "first_name",
    sortMode: "asc",
  });

  const selected = React.useMemo(
    () => managers?.data.find((it) => it.id == value),
    [managers, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={managers?.data || []}
      value={selected || null}
      getOptionLabel={(option) =>
        `${option.first_name} ${option.last_name}` || "?"
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search..."
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue: Manager | null) => {
        onChange(newValue?.id);
      }}
      loading={isLoading}
    />
  );
};
export default SelectManager;
