import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { RootState } from "../app/store";
import { BASE_API_URL } from "../configs/api";

export interface StripeQueryResponse<T> {
  data: Array<T>;
  has_more: boolean;
}

export interface QueryResponse<T> {
  data: Array<T>;
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total: number;
  };
}

const rawBaseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = (getState() as RootState).auth.token?.accessToken;
    if (accessToken) {
      headers.set("authorization", `Bearer ${accessToken}`);
    }
    headers.set("accept", "application/json");
    return headers;
  },
});

// initialize an empty api service that we'll inject endpoints into later as needed
// export const baseApi = createApi({
//   baseQuery: rawBaseQuery,
//   endpoints: () => ({}),
//   refetchOnMountOrArgChange: 30, // refetch after 30s
//   refetchOnReconnect: false,
//   refetchOnFocus: false,
// });

const companyBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  //@ts-ignore
  if (extraOptions?.isCompanyApi) {
    const companyId = (api.getState() as RootState).auth.user?.company.id;
    if (!companyId) {
      return {
        error: {
          status: 400,
          data: "No company ID received",
        },
      };
    }
    const urlEnd = typeof args === "string" ? args : args.url;
    const adjustedUrl = `api/company/${companyId}/${urlEnd}`;
    const adjustedArgs =
      typeof args === "string" ? adjustedUrl : { ...args, url: adjustedUrl };
    return rawBaseQuery(adjustedArgs, api, extraOptions);
  } else {
    return rawBaseQuery(args, api, extraOptions);
  }
};

export const baseApi = createApi({
  baseQuery: companyBaseQuery,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: 30, // refetch after 30s
  refetchOnReconnect: false,
  refetchOnFocus: false,
});
