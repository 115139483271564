import dayjs from "dayjs";
import { Project, ProjectResource, ReportProgress } from "../types/project";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_PROJECT, TAG_TYPE_PROJECT_JOB } from "./tags";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

//@ts-ignore
const myTimezone = dayjs.tz.guess();

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_PROJECT_JOB, TAG_TYPE_PROJECT],
});

export const projectJobApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjectBids: builder.query<Array<ProjectResource>, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (projectId) => ({
        url: `projects/${projectId}`,
        method: "GET",
        params: {
          load: "resources,resources.resource,resources.reportProgresses",
        },
      }),
      transformResponse: (response: Project) => {
        return response.resources || [];
      },
      providesTags: (result, error, projectId) => {
        return [{ type: TAG_TYPE_PROJECT_JOB, id: projectId }];
      },
    }),
    getReportProgress: builder.query<
      ReportProgress,
      { projectId: number; progressId: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, progressId }) => ({
        url: `report-progresses/${projectId}/${progressId}/show`,
        method: "GET",
        params: {
          load: "documents",
        },
      }),
    }),
    acceptBid: builder.mutation<void, { projectId: number; jobId: number }>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, jobId }) => ({
        url: `projects/${projectId}/${jobId}/accepted`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT_JOB, id: payload.projectId },
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
      ],
    }),
    hireTalent: builder.mutation<
      void,
      {
        projectId: number;
        jobId: number;
        rate?: number;
        max_hour?: number;
        price?: number;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, jobId, rate, max_hour, price }) => ({
        url: `projects/${projectId}/${jobId}/hired`,
        method: "PUT",
        params: { rate, max_hour, price },
      }),
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT_JOB, id: payload.projectId },
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
      ],
    }),
    cancelTalent: builder.mutation<
      void,
      {
        projectId: number;
        jobId: number;
        reason: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, jobId, reason }) => ({
        url: `projects/${projectId}/${jobId}/canceled`,
        method: "PUT",
        body: {
          canceled_reason: reason,
        },
      }),
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT_JOB, id: payload.projectId },
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
      ],
    }),
    approveTalentWork: builder.mutation<
      void,
      { projectId: number; progressId: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, progressId }) => ({
        url: `report-progresses/${projectId}/${progressId}/approved`,
        method: "PUT",
      }),
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT_JOB, id: payload.projectId },
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
      ],
    }),
    rejectTalentWork: builder.mutation<
      void,
      { projectId: number; progressId: number; reason: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, progressId, reason }) => ({
        url: `report-progresses/${projectId}/${progressId}/rejected`,
        method: "PUT",
        body: {
          reason,
        },
      }),
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT_JOB, id: payload.projectId },
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
      ],
    }),
    forceCheckout: builder.mutation<
      void,
      { projectId: number; progressId: number; checkoutDate: Date }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, progressId, checkoutDate }) => ({
        url: `report-progresses/${projectId}/${progressId}/checkout`,
        method: "PUT",
        params: {
          check_out_date: dayjs(checkoutDate).format("YYYY-MM-DD HH:mm"),
          check_out_timezone: myTimezone,
        },
      }),
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT_JOB, id: payload.projectId },
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
      ],
    }),
    inviteTalent: builder.mutation<
      Project,
      { project_id: number; resource_id: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ project_id, resource_id }) => {
        return {
          url: `projects/${project_id}/invited`,
          method: "POST",
          body: {
            resource: resource_id,
          },
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT_JOB, id: payload.project_id },
        { type: TAG_TYPE_PROJECT, id: payload.project_id },
      ],
    }),
    giveBonus: builder.mutation<
      void,
      {
        project_id: number;
        resource_id: number;
        total: number;
        type: number;
        user_comment: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ project_id, resource_id, total, type, user_comment }) => {
        return {
          url: `bonuses`,
          method: "POST",
          body: {
            project: project_id,
            talent: resource_id,
            total,
            type,
            user_comment,
          },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectBidsQuery,
  useGetReportProgressQuery,
  useAcceptBidMutation,
  useHireTalentMutation,
  useCancelTalentMutation,
  useApproveTalentWorkMutation,
  useRejectTalentWorkMutation,
  useForceCheckoutMutation,
  useInviteTalentMutation,
  useGiveBonusMutation,
} = projectJobApi;
