import { Reason, ReasonType } from "../types/reason";
import { baseApi } from "./base";
import { TAG_TYPE_REASON } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE_REASON] });

export const reasonApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getReasonsByType: builder.query<Array<Reason>, ReasonType>({
      query: (type) => ({
        url: "api/reasons",
        method: "GET",
        params: {
          type,
        },
      }),
      providesTags: (result, error, type) => [
        { type: TAG_TYPE_REASON, id: type },
      ],
      keepUnusedDataFor: 300,
    }),
  }),
  overrideExisting: false,
});

export const { useGetReasonsByTypeQuery } = reasonApi;
