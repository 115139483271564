import { Location } from "../types/location";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_LOCATION } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_LOCATION],
});

export const locationApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getLocations: builder.query<
      QueryResponse<Location>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        withTrashed?: boolean;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({
        page = 0,
        pageSize = 10000,
        sortField,
        sortMode,
        search,
        withTrashed,
      }) => ({
        url: "locations",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          withTrashed,
          search,
          load: "country",
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_LOCATION, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_LOCATION, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_LOCATION, id })),
        ];
      },
    }),
    getLocationById: builder.query<Location, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `locations/${id}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_LOCATION, id }],
    }),
    addLocation: builder.mutation<Location, Omit<Location, "id">>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `locations`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_LOCATION, id: "PARTIAL-LIST" },
      ],
    }),
    editLocation: builder.mutation<
      Location,
      Partial<Location> & Pick<Location, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `locations/${body.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_LOCATION, id: data.id },
        { type: TAG_TYPE_LOCATION, id: "PARTIAL-LIST" },
      ],
    }),
    deleteLocation: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `locations/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_LOCATION, id },
        { type: TAG_TYPE_LOCATION, id: "PARTIAL-LIST" },
      ],
    }),
    restoreLocation: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `locations/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_LOCATION, id },
        { type: TAG_TYPE_LOCATION, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetLocationsQuery,
  useGetLocationByIdQuery,
  useAddLocationMutation,
  useEditLocationMutation,
  useDeleteLocationMutation,
  useRestoreLocationMutation,
} = locationApi;
