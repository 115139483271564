import * as React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import {
  useTimezoneSelect,
  allTimezones,
  ITimezoneOption,
} from "react-timezone-select";

const SelectTimezone = ({
  onChange,
  sx,
  value,
  label = "Client",
  error,
  helperText,
  disabled = false,
}: {
  onChange: (arg?: string) => void;
  sx?: any;
  value: string;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
}) => {
  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle: "original",
    timezones: allTimezones,
  });

  const selected = React.useMemo(
    () => options?.find((it) => it.value == value),
    [options, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={options || []}
      value={selected || null}
      getOptionLabel={(option: ITimezoneOption) => `${option.label}`}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search..."
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue: ITimezoneOption | null) => {
        console.log("Val", newValue?.value);
        onChange(newValue?.value);
      }}
      // loading={isLoading || isFetching}
      disabled={disabled}
    />
  );
};
export default SelectTimezone;
