import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { Project } from "../../types/project";
import MapPreview from "../../components/MapPreview";
import { nl2br } from "../../utils/helper";
import { formatWithTimezone } from "../../utils/dateHelper";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 2),
  marginTop: 40,
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const containerStyle = {
  width: "100%",
  height: "400px",
};

const options = {
  fullscreenControl: false, // remove the top-right button
  mapTypeControl: false, // remove the top-left buttons
  streetViewControl: false, // remove the pegman
  zoomControl: true, // remove the bottom-right buttons
  scrollwheel: false,
};

interface ProjectDrawerProps {
  data: Project;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ProjectDrawer(props: ProjectDrawerProps) {
  const { data, open, setOpen } = props;

  const navigate = useNavigate();

  const [lng, lat] = React.useMemo(() => {
    if (!!data?.customer) {
      return data?.customer?.coordinate?.coordinates || [];
    } else if (!!data?.location) {
      return data?.location?.coordinate?.coordinates || [];
    } else return [];
  }, [data]);

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpen(open);
    };

  const list = () => (
    <Box sx={{ width: 450, mb: 8 }} role="presentation">
      <DrawerHeader>
        <ClearIcon
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        />
      </DrawerHeader>
      <MapPreview lat={lat} lng={lng} />
      <List>
        <ListItem>
          <ListItemText
            primary={`ID/Title`}
            secondary={`#${data?.id} ${data?.title}`}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={`Scope of Work`}
            secondary={nl2br(data?.description)}
          />
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={`Start At`}
            secondary={formatWithTimezone(data?.start_date, data?.timezone)}
          />
        </ListItem>
        <Divider />
        {!!data?.location && (
          <ListItem>
            <ListItemText
              primary={`Location Address`}
              secondary={`${data?.location.address_1}, ${data?.location.city}, ${data?.location.zip}`}
            />
          </ListItem>
        )}
        {!!data?.customer && (
          <ListItem>
            <ListItemText
              primary={`Client Address`}
              secondary={`${data?.customer?.physical_address_1}, ${data?.customer?.city}, ${data?.customer?.zip}`}
            />
          </ListItem>
        )}
        <Divider />
        <ListItem>
          <ListItemText
            primary={`Internal Notes`}
            secondary={data?.internal_notes || "n/a"}
          />
        </ListItem>
      </List>
      <Box display="flex" justifyContent="center" sx={{ mt: 5 }}>
        <Button
          variant="contained"
          onClick={() => navigate(`/jobs/${data?.id}`)}
        >
          More Detail
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        {/*<Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button>*/}
        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
