import dayjs from "dayjs";
import { ShiftTemplate, Template } from "../types/template";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_SHIFT_TEMPLATE, TAG_TYPE_TEMPLATE } from "./tags";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

//@ts-ignore
const myTimezone = dayjs.tz.guess();

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_TEMPLATE],
});

export const templateApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query<
      QueryResponse<Template>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        load?: string;
        status?: string;
        statuses?: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({
        page = 0,
        pageSize = 25,
        sortField,
        sortMode,
        search,
        load = "customer,location,skills,certifications",
        status,
        statuses,
      }) => ({
        url: "templates",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `title|asc`,
          // withTrashed: true,
          search,
          load,
          status,
          statuses,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_TEMPLATE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_TEMPLATE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_TEMPLATE, id })),
        ];
      },
    }),
    getTemplateById: builder.query<Template, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `templates/${id}`,
        method: "GET",
        params: {
          load: "customer,location,skills,certifications",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_TEMPLATE, id }],
    }),
    addTemplate: builder.mutation<
      Template,
      Omit<Template, "id"> & { timezone: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        body.timezone = myTimezone;
        return {
          url: `templates`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_TEMPLATE, id: "PARTIAL-LIST" },
      ],
    }),
    editTemplate: builder.mutation<
      Template,
      Partial<Template> & Pick<Template, "id"> & { timezone: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        body.timezone = myTimezone;
        return {
          url: `templates/${body.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_TEMPLATE, id: data.id },
        { type: TAG_TYPE_TEMPLATE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteTemplate: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `templates/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_TEMPLATE, id },
        { type: TAG_TYPE_TEMPLATE, id: "PARTIAL-LIST" },
      ],
    }),
    getShiftTemplates: builder.query<
      QueryResponse<ShiftTemplate>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        load?: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ page = 0, pageSize = 25, sortField, sortMode, search }) => ({
        url: "shifts",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          // withTrashed: true,
          search,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data)
          return [{ type: TAG_TYPE_SHIFT_TEMPLATE, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_SHIFT_TEMPLATE, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_SHIFT_TEMPLATE, id })),
        ];
      },
    }),
    getShiftTemplateById: builder.query<ShiftTemplate, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `shifts/${id}`,
        method: "GET",
        params: {
          load: "customer,location,skills,certifications",
        },
      }),
      providesTags: (result, error, id) => [
        { type: TAG_TYPE_SHIFT_TEMPLATE, id },
      ],
    }),
    addShiftTemplate: builder.mutation<
      ShiftTemplate,
      Omit<ShiftTemplate, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `shifts`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_SHIFT_TEMPLATE, id: "PARTIAL-LIST" },
      ],
    }),
    editShiftTemplate: builder.mutation<
      ShiftTemplate,
      Partial<ShiftTemplate> & Pick<ShiftTemplate, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `shifts/${body.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_SHIFT_TEMPLATE, id: data.id },
        { type: TAG_TYPE_SHIFT_TEMPLATE, id: "PARTIAL-LIST" },
      ],
    }),
    deleteShiftTemplate: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `shifts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_SHIFT_TEMPLATE, id },
        { type: TAG_TYPE_SHIFT_TEMPLATE, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTemplatesQuery,
  useGetTemplateByIdQuery,
  useAddTemplateMutation,
  useEditTemplateMutation,
  useDeleteTemplateMutation,
  //
  useGetShiftTemplatesQuery,
  useGetShiftTemplateByIdQuery,
  useAddShiftTemplateMutation,
  useEditShiftTemplateMutation,
  useDeleteShiftTemplateMutation,
} = templateApi;
