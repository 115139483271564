import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";

interface ConfirmModalProps {
  open: boolean;
  title: string;
  message?: string;
  onConfirm: () => Promise<void>;
  onClose: () => void;
}

const ConfirmModal = ({
  open,
  title,
  message,
  onClose,
  onConfirm,
}: ConfirmModalProps) => {
  const [loading, setLoading] = React.useState(false);

  const handleConfirm = React.useCallback(() => {
    setLoading(true);
    onConfirm().finally(() => {
      setLoading(false);
      onClose();
    });
  }, [onConfirm]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || ""}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          onClick={handleConfirm}
          autoFocus
          loading={loading}
          variant={"contained"}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmModal;
