import { Box, Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../../hooks/useTitle";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { useCompany } from "../../hooks/useCompany";
import { CompanyStatus } from "../../types/company";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);

const DashboardPage = () => {
  const user = useCurrentUser();
  const company = useCompany();
  const navigate = useNavigate();

  useTitle(company?.name || "Dashboard");

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", mb: 0 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h5">
                Welcome Back, {user?.full_name} 👋
              </Typography>
              <Typography variant="caption">
                {user?.company?.name} - {dayjs().format("ddd, MMMM DD YYYY z")}
              </Typography>
              {/* <Typography variant="h5">Welcome to your Dashboard</Typography> */}
            </Box>
            <Button
              variant="contained"
              onClick={() => navigate(`/jobs/create`)}
              disabled={company?.status != CompanyStatus.ACTIVE}
            >
              + Create New Job
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default DashboardPage;
