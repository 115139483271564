import dayjs from "dayjs";
import {
  NegotiationStatus,
  Project,
  ProjectResource,
  ProjectStatus,
  ReportProgress,
  ReportProgressStatus,
} from "../types/project";

export function getLastProgress(
  job: ProjectResource
): ReportProgress | undefined {
  const sorted = job.report_progresses
    ?.slice()
    .sort(
      (a: ReportProgress, b: ReportProgress) =>
        new Date(b.check_in).getTime() - new Date(a.check_in).getTime()
    );
  const progress = sorted?.[0];
  return progress;
}

export const getTalentBidStatus = (
  status: NegotiationStatus
): {
  label: string;
  color: any;
} => {
  if (status == NegotiationStatus.NEW) {
    return {
      label: "NEW",
      color: undefined,
    };
  } else if (status == NegotiationStatus.HIRED) {
    return {
      label: "HIRED",
      color: "success",
    };
  } else if (status == NegotiationStatus.INTERVIEW) {
    return {
      label: "INTERVIEWED",
      color: "warning",
    };
  } else if (status == NegotiationStatus.INVITED) {
    return {
      label: "INVITED",
      color: "info",
    };
  } else if (status == NegotiationStatus.REJECTED) {
    return {
      label: "REJECTED",
      color: "info",
    };
  } else if (status == NegotiationStatus.CANCELED) {
    return {
      label: "CANCELED",
      color: "error",
    };
  } else {
    return { label: status, color: undefined };
  }
};

export const getHiredTalentStatus = (
  project: Project,
  job: ProjectResource
): {
  label: string;
  color: any;
  helperText?: string;
} => {
  const progress = getLastProgress(job);
  if (project.status == ProjectStatus.PUBLISHED) {
    return {
      label: "WAIT WORKER",
      color: undefined,
      helperText: "Waiting for more workers",
    };
  } else if (progress?.status == ReportProgressStatus.APPROVED) {
    return { label: "WORK APPROVED", color: "success" };
  } else if (progress?.status == ReportProgressStatus.REJECTED) {
    return { label: "WORK REJECTED", color: "error" };
  } else if (progress?.status == ReportProgressStatus.SUBMITTED) {
    return {
      label: "WAIT APPROVAL",
      color: "info",
    };
  } else if (!!progress?.check_in && !progress?.check_out) {
    return {
      label: "CHECKED IN",
      color: "info",
      helperText: `at ${dayjs(progress?.check_in).format("MMM DD hh:mm A")}`,
    };
  } else if (!!progress?.check_in && !!progress?.check_out) {
    return {
      label: "CHECKED OUT",
      color: "warning",
      helperText: `at ${dayjs(progress?.check_out).format("MMM DD hh:mm A")}`,
    };
  }
  return { label: "SCHEDULED", color: undefined };
};
