import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useTitle } from "../../hooks/useTitle";
import { LoadingButton } from "@mui/lab";
import {
  FilePresent,
  RemoveCircleOutlineOutlined,
  UploadFile,
} from "@mui/icons-material";
import React from "react";
import { toast } from "react-toastify";
import { useFilePicker } from "use-file-picker";
import { useUploadTalentImportFileMutation } from "../../services/talent.service";

const TalentsInvitePage = () => {
  useTitle("Invite Employees");

  const [openFileSelector, { plainFiles, loading, errors, clear }] =
    useFilePicker({
      multiple: false,
      accept: [".xlsx,.xls"],
      maxFileSize: 50,
      readFilesContent: false,
    });
  const [uploadFile, { isLoading: uploading }] =
    useUploadTalentImportFileMutation();

  const [file, setFile] = React.useState<File | null>();

  const _uploadDoc = async (file: File) => {
    try {
      await uploadFile({ file }).unwrap();
      clear();
      toast.success("Emails sent.");
    } catch (err: any) {
      toast.error(err.message);
      clear();
    }
  };

  React.useEffect(() => {
    if (plainFiles?.length > 0) {
      const file = plainFiles[0];
      _uploadDoc(file);
    }
  }, [plainFiles]);

  React.useEffect(() => {
    errors?.[0]?.fileSizeTooSmall && toast.error("File size is too small!");
    errors?.[0]?.fileSizeToolarge && toast.error("File size is too large!");
    errors?.[0]?.readerError &&
      toast.error("Problem occured while reading file!");
    errors?.[0]?.maxLimitExceeded && toast.error("Too many files");
    errors?.[0]?.minLimitNotReached && toast.error("Not enought files");
  }, [errors]);

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Card>
        <CardHeader title="Import File" />
        <CardContent>
          <Typography variant="body1">
            We will send invitations to the email addresses listed in the
            uploaded Excel file. <br />
            Employees are required to register via the registration link
            provided in the email in order to join your company.
            <br />
            <br />
            Sample Excel file :{" "}
            <a
              target="_blank"
              href="https://firebasestorage.googleapis.com/v0/b/the-method-329619.appspot.com/o/sample-import.xlsx?alt=media&token=36020c10-e9e4-44b0-96f1-10a5677106ef"
            >
              Download
            </a>
          </Typography>
          <Box sx={{ mt: 2 }}>
            {file && (
              <List>
                <ListItem
                  key={file.name}
                  secondaryAction={
                    <IconButton
                      onClick={() => {
                        setFile(null);
                      }}
                    >
                      <RemoveCircleOutlineOutlined
                        fontSize="small"
                        color="error"
                      />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    <FilePresent />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${file.name || "n/a"}`}
                    primaryTypographyProps={{
                      fontWeight: "500",
                      fontSize: 16,
                    }}
                    sx={{ flex: 1 }}
                  />
                </ListItem>
              </List>
            )}
          </Box>
          <LoadingButton
            variant="contained"
            size="large"
            onClick={() => openFileSelector()}
            startIcon={<UploadFile />}
            sx={{ ml: 1, mt: 1 }}
            loading={uploading}
          >
            Upload file
          </LoadingButton>
        </CardContent>
      </Card>
    </Box>
  );
};
export default TalentsInvitePage;
