import { Country } from "../types/country";
import { baseApi } from "./base";
import { TAG_TYPE_COUNTRY } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_COUNTRY],
});
//git test
export const countryApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<Array<Country>, void>({
      query: () => ({
        url: "api/countries",
        method: "GET",
        params: {
          sort: `name|asc`,
        },
      }),
      providesTags: [{ type: TAG_TYPE_COUNTRY, id: "LIST" }],
      keepUnusedDataFor: 600,
    }),
  }),
  overrideExisting: false,
});

export const { useGetCountriesQuery } = countryApi;
