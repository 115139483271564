import * as React from "react";
import {
  Button,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  IconButton,
  Link,
  Table,
  Typography,
} from "@mui/material";
import { Talent } from "../../types/users";
import { useNavigate } from "react-router-dom";
// import EditIcon from "@mui/icons-material/Edit";
import dayjs from "dayjs";

const TalentProfilePanel = ({ data }: { data?: Talent }) => {
  const navigate = useNavigate();
  return (
    <Grid container spacing={1} sx={{ mt: 4 }}>
      <Grid item xs={8}>
        <Card>
          <CardHeader title="About" />
          <CardContent>{data?.bio}</CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Skills" />
          <CardContent>
            {data?.skills?.length == 0
              ? "No skills added"
              : data?.skills?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Languages" />
          <CardContent>
            {data?.languages?.length == 0
              ? "No languages added"
              : data?.languages?.map((it) => (
                  <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                ))}
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Certificates & Licenses" />
          <CardContent>
            <Grid container>
              {data?.certifications.map((item) => (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2 }}>
                    <Link href={item?.certification_url} target="blank">
                      {item?.certification?.name}
                    </Link>
                    <Typography sx={{ fontSize: 14 }} component="div">
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Number #
                      </Box>
                      : {`${item?.certification_number || "n/a"}`}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Expires
                      </Box>
                      :{" "}
                      {item?.expiration_date
                        ? dayjs(item?.expiration_date).format("MM/DD/YYYY")
                        : "n/a"}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Work Experiences" />
          <CardContent>
            <Grid container>
              {data?.work_experiences.map((item) => (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      fontWeight="fontWeightMedium"
                    >
                      {item?.company || "n/a"}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      <Box sx={{ fontStyle: "italic" }}>
                        {`${dayjs()
                          .set("month", item?.start_period_month - 1)
                          .set("year", item?.start_period_year)
                          .format("MMM YYYY")} to ${
                          item?.end_period_month && item?.end_period_year
                            ? dayjs()
                                .set("month", item?.end_period_month - 1)
                                .set("year", item?.end_period_year)
                                .format("MMM YYYY")
                            : "present"
                        }`}
                      </Box>
                      <Box>{item?.description || "n/a"}</Box>
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Educations" />
          <CardContent>
            <Grid container>
              {data?.educations.map((item) => (
                <Grid item xs={12} sm={6}>
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      fontWeight="fontWeightMedium"
                    >
                      {item?.school}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                      <Box sx={{ fontStyle: "italic" }}>
                        {`${dayjs(item?.start_date).format("MM/DD/YYYY")} to ${
                          item?.end_date
                            ? dayjs(item?.end_date).format("MM/DD/YYYY")
                            : "present"
                        }`}
                      </Box>
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} component="div">
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Degree
                      </Box>
                      : {item?.degree || "n/a"}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} component="div">
                      <Box fontWeight="fontWeightMedium" display="inline">
                        Field of study
                      </Box>
                      : {item?.field_of_study || "n/a"}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={4}>
        <Card sx={{ mt: 0 }}>
          <CardHeader title="Contact" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td>Address 1</td>
                  <td>{data?.physical_address_1}</td>
                </tr>
                <tr>
                  <td>Address 2</td>
                  <td>{data?.physical_address_2}</td>
                </tr>
                <tr>
                  <td>City / Zip</td>
                  <td>
                    {data?.city} / {data?.zip}
                  </td>
                </tr>
                <tr>
                  <td>State</td>
                  <td>{data?.state}</td>
                </tr>
                <tr>
                  <td>Country</td>
                  <td>{data?.country?.name || "n/a"}</td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="Social Medias" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td>LinkedIn</td>
                  <td>{data?.linkedin || "n/a"}</td>
                </tr>
                <tr>
                  <td>Facebook</td>
                  <td>{data?.facebook || "n/a"}</td>
                </tr>
                <tr>
                  <td>Instagram</td>
                  <td>{data?.instagram || "n/a"}</td>
                </tr>
                <tr>
                  <td>Website</td>
                  <td>{data?.website || "n/a"}</td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
        <Card sx={{ mt: 1 }}>
          <CardHeader title="More Info" />
          <CardContent>
            <Table sx={{ fontSize: 14 }}>
              <tbody>
                <tr>
                  <td>COVID-19 vaccine required</td>
                  <td>{!!data?.covid_vaccinated ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Background Check</td>
                  <td>
                    {!!data?.covid_vaccinated ? "Allowed" : "Not Allowed"}
                  </td>
                </tr>
                <tr>
                  <td>Drug Test</td>
                  <td>
                    {!!data?.covid_vaccinated ? "Allowed" : "Not Allowed"}
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
export default TalentProfilePanel;
