import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridToolbarExport,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { Location } from "../../types/location";
import { useNavigate } from "react-router-dom";
import LocationModalForm from "./LocationModalForm";
import LocationDrawer from "./LocationDrawer";
import {
  useDeleteLocationMutation,
  useGetLocationsQuery,
} from "../../services/location.service";

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{ allColumns: true, fileName: "Locations" }}
        />
        <Button variant="contained" onClick={onCreate} sx={{ ml: 2 }}>
          <AddIcon /> Add Location
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
};

const LocationsPage = () => {
  useTitle("Locations");
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<Location> | null;
  }>({
    open: false,
    row: null,
  });
  const [formModal, setFormModal] = React.useState<{
    open: boolean;
    data: Location | null;
  }>({
    open: false,
    data: null,
  });
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<Location | null>(null);

  const { data, isLoading, isFetching } = useGetLocationsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete, { isLoading: deleting }] = useDeleteLocationMutation();

  const handleDeleteRow = (row: GridRowModel<Location>) => {
    setDeleteModal({ open: true, row });
  };
  const handleEditRow = (row: GridRowModel<Location>) => {
    setFormModal({ open: true, data: row });
  };

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "first_name",
        headerName: "Site Number/Location Name",
        flex: 1,
        minWidth: 150,
        sortingOrder: ["desc", "asc", null],
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Location>) => (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setCurrentItem(params.row);
              setOpen(true);
            }}
          >
            {params.row.name}
          </Link>
        ),
        disableExport: true,
      },
      {
        field: "name",
        headerName: "Site Number/Location Name",
        hide: true,
      },
      {
        field: "site_number",
        headerName: "Client Name",
        //flex: 0,
        minWidth: 250,
        hide: false,
      },
      {
        field: "primary_contact_name",
        headerName: "Contact Name",
        headerAlign: "center",
        minWidth: 200,
        sortable: true,
        filterable: true,
        align: "center",
      },
      {
        field: "primary_contact_phone_number",
        headerName: "Phonenumber",
        hide: true,
      },
      {
        field: "address_1",
        headerName: "Address",
        hide: true,
      },
      {
        field: "city",
        headerName: "City",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
      },
      {
        field: "state",
        headerName: "State",
        headerAlign: "center",
        minWidth: 140,
        sortable: true,
        filterable: true,
        align: "center",
      },
      {
        field: "zip",
        headerName: "Zip",
        hide: true,
      },
      {
        field: "control",
        headerName: "Edit/Delete",
        headerAlign: "center",
        minWidth: 140,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Location>) => (
          <Stack direction={"row"}>
            <IconButton onClick={() => handleEditRow(params.row)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => handleDeleteRow(params.row)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Stack>
        ),
        disableExport: true,
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    setFormModal({ open: true, data: null });
    // navigate(`/locations/create`);
  }, []);

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Paper style={{ height: "85vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[100]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          // rowHeight={42}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
        <ConfirmDeleteModal
          open={deleteModal.open}
          onClose={closeDeleteModal}
          onDelete={handleDelete}
          message={`Delete '${deleteModal.row?.name}' ?`}
        />
        <LocationModalForm
          open={formModal.open}
          onClose={() => setFormModal({ open: false, data: null })}
          data={formModal.data}
        />
      </Paper>

      <LocationDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Box>
  );
};
export default LocationsPage;
