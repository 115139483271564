import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export function timeSince(date: Date): string {
  let seconds = Math.floor(
      new Date().getTime() / 1000 - new Date(date).getTime() / 1000
    ),
    interval = Math.floor(seconds / 31536000);

  // if (interval > 1) return interval + 'y';

  // interval = Math.floor(seconds / 2592000);
  // if (interval > 1) return interval + 'm';

  interval = Math.floor(seconds / 86400);
  if (interval >= 2 && interval <= 7) return interval + "d ago";
  if (interval > 7) return dayjs(date).format("MMM DD, YYYY hh:mm A z");

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return interval + "h ago";

  interval = Math.floor(seconds / 60);
  if (interval > 1) return interval + "m ago";

  return Math.floor(seconds) + "s ago";
}

export function isLast24Hr(date: number): boolean {
  let seconds = Math.floor(new Date().getTime() / 1000 - date),
    interval = Math.floor(seconds / 31536000);

  interval = Math.floor(seconds / 3600);
  return interval < 24;
}

export function timestamp(date: number, timezone: string) {
  let t = dayjs(date);
}

export function elapsed(
  endMillis: number,
  startMillis?: number,
  withSecond: boolean = true
): string {
  let seconds;
  if (!startMillis)
    seconds = Math.floor(new Date().getTime() / 1000 - endMillis / 1000);
  else seconds = Math.floor(endMillis / 1000 - startMillis / 1000);
  let hour = Math.floor(seconds / 3600);
  let min = Math.floor((seconds % 3600) / 60);
  let sec = seconds % 60;
  if (withSecond) return `${hour}h ${min}m ${sec}s`;
  else return `${hour}h ${min}m`;
}

export function toDuration(
  seconds: number,
  withSecond: boolean = false
): string {
  let hour = Math.floor(seconds / 3600);
  let min = Math.floor((seconds % 3600) / 60);
  let sec = Math.floor(seconds % 60);
  if (withSecond) return `${hour}h ${min}m ${sec}s`;
  else return `${hour}h ${min}m`;
}

export function formatWithTimezone(
  utcDate?: Date,
  timezone?: string,
  format?: string
) {
  if (!timezone)
    return dayjs(utcDate).format(!!format ? format : "ddd, MMM D - h:mmA z");

  dayjs
    .utc(utcDate)
    .tz(timezone || "America/Chicago")
    .format();

  return dayjs
    .utc(utcDate)
    .tz(timezone || "America/Chicago")
    .format(!!format ? format : "ddd, MMM D - h:mmA z");
}
