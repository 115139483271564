import * as React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  List,
  ListItemText,
  ListItemAvatar,
  ListItemButton,
  Chip,
  Rating,
  Stack,
  Link,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import { Project, ProjectResource } from "../../types/project";
// import { Talent } from "../../types/users";
import { useTitle } from "../../hooks/useTitle";
import { useGetProjectsQuery } from "../../services/project.service";
import dayjs from "dayjs";
import MapMarkerListPreview from "../../components/MapMarkerListPreview";
import { LoadingButton } from "@mui/lab";
import ProjectResourceDrawer from "./ProjectResourceDrawer";
import ProjectDrawer from "../project/ProjectDrawer";
import { MyLocation, Refresh } from "@mui/icons-material";
import { getHiredTalentStatus } from "../../utils/projectHelper";
import { formatWithTimezone } from "../../utils/dateHelper";

interface ICoordinate {
  lat: number;
  lng: number;
}

const ProjectsMapPage = () => {
  useTitle("Jobs Map");

  const [openTalent, setOpenTalent] = React.useState(false);
  const [currentTalentItem, setCurrentTalentItem] =
    React.useState<ProjectResource | null>(null);

  const [openProject, setOpenProject] = React.useState(false);
  const [currentProjectItem, setCurrentProjectItem] =
    React.useState<Project | null>(null);

  const [coodinate, setCoordinate] = React.useState<ICoordinate | null>(null);
  const [selected, setSelected] = React.useState(0);

  const { data, isFetching, refetch } = useGetProjectsQuery({
    pageSize: 50, // get all one time , note: need to implement infinite scroll if we have more data
    sortField: "start_date",
    sortMode: "desc",
    statuses: "in-progress,publish",
  });

  const rows = React.useMemo(() => data?.data, [data]);

  const handleProjectClick = (row: Project) => {
    let _coordinates: number[] = [];
    if (!!row?.customer) {
      _coordinates = row?.customer?.coordinate?.coordinates;
    } else if (!!row?.location) {
      _coordinates = row?.location?.coordinate?.coordinates || [];
    }
    setSelected(row.id);
    setCurrentProjectItem(row);
    setCoordinate({ lat: _coordinates[1] || 0, lng: _coordinates[0] || 0 });
  };

  const handleTalentClick = (row: ProjectResource) => {
    let _coordinates: number[] = [];
    if (!!row?.resource) {
      _coordinates = row?.resource?.coordinate?.coordinates;
    }
    setSelected(row.project_id);
    setCoordinate({ lat: _coordinates[1] || 0, lng: _coordinates[0] || 0 });
  };

  React.useEffect(() => {
    if (rows && rows.length > 0) {
      let _coordinates: number[] = [];
      if (!!rows[0].customer) {
        _coordinates = rows[0].customer?.coordinate?.coordinates;
      } else if (!!rows[0].location) {
        _coordinates = rows[0].location?.coordinate?.coordinates || [];
      }
      setSelected(rows[0].id);
      setCoordinate({ lat: _coordinates[1] || 0, lng: _coordinates[0] || 0 });
    }
  }, [rows]);

  return (
    <Box>
      <Grid container sx={{ height: "85vh" }} boxShadow={1}>
        <Grid item xs={4} sx={{ bgcolor: "background.paper" }}>
          <Box sx={{ overflow: "auto", p: 1, height: "85vh" }}>
            <Box display="flex" sx={{ flexDirection: "row-reverse" }}>
              <LoadingButton
                variant="outlined"
                disabled={isFetching}
                loading={isFetching}
                startIcon={<Refresh />}
                onClick={refetch}
              >
                Refresh
              </LoadingButton>
            </Box>
            <Divider sx={{ mt: 1 }} />
            <List component="nav">
              {rows?.length == 0 && (
                <Box>
                  <p style={{ textAlign: "center" }}>No Jobs, yet</p>
                </Box>
              )}
              {rows?.map((row, index) => {
                return (
                  <div key={`#list-item-${index}`}>
                    <ListItemButton
                      key={`#project-${row.id}`}
                      alignItems="flex-start"
                      selected={row.id === selected}
                      dense={true}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <HomeRepairServiceIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Link
                              onClick={() => {
                                setOpenProject(true);
                                setCurrentProjectItem(row);
                              }}
                            >
                              <Typography>
                                {`#${row.id} - ${row.title}`}
                              </Typography>
                            </Link>
                            <Typography variant="body2" component="span">
                              {row?.status?.toUpperCase()}
                            </Typography>
                            {" — "}
                            <Typography component="span" variant="body2">
                              {!!row.customer
                                ? `${row.customer.first_name} ${row.customer.last_name}`
                                : !!row.location
                                ? row.location.name
                                : "?"}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={formatWithTimezone(
                          row.start_date,
                          row.timezone
                        )}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => handleProjectClick(row)}>
                          <MyLocation color="primary" fontSize="small" />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItemButton>

                    {row.resources
                      ?.slice(0, 50)
                      .map((resourceItem: ProjectResource) => {
                        const status = getHiredTalentStatus(row, resourceItem);
                        return (
                          <ListItemButton
                            key={`#resource-${resourceItem?.resource_id}`}
                            selected={resourceItem.project_id === selected}
                            sx={{
                              pl: 6,
                              borderTop: "1px dashed #eee",
                              py: 0.5,
                            }}
                            onClick={() => {
                              handleTalentClick(resourceItem);
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar
                                alt={`${resourceItem?.resource?.full_name}`}
                                src={
                                  (!!resourceItem?.resource?.photo?.length &&
                                    resourceItem?.resource?.photo[0]
                                      .original_url) ||
                                  "broken-image.jpg"
                                }
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <Stack direction="row" alignItems={"center"}>
                                  {resourceItem?.resource?.full_name}{" "}
                                  <Rating
                                    readOnly
                                    size="small"
                                    value={resourceItem?.resource?.total_rating}
                                    precision={0.5}
                                    sx={{ ml: 2 }}
                                  />
                                </Stack>
                              }
                              secondary={
                                <Stack direction={"row"} alignItems="center">
                                  <Chip
                                    label={status.label}
                                    color={status.color}
                                    size="small"
                                    sx={{ height: 20, fontSize: 12 }}
                                  />
                                  {!!status.helperText && (
                                    <Typography
                                      variant="caption"
                                      sx={{ ml: 1 }}
                                    >
                                      {status.helperText}
                                    </Typography>
                                  )}
                                </Stack>
                              }
                            />
                            {/* <ListItemSecondaryAction>
                              secondary=
                              {!!data?.resource?.coordinate
                                ? `${+Number(
                                    getDistanceBetweenPoints(
                                      {
                                        lat:
                                          data?.resource?.coordinate
                                            ?.coordinates[1] || 0,
                                        lng:
                                          data?.resource?.coordinate
                                            ?.coordinates[0] || 0,
                                      },
                                      projectCoordinate
                                        ? projectCoordinate
                                        : { lat: 0, lng: 0 }
                                    )
                                  ).toFixed(2)} miles`
                                : `n/a`}
                            </ListItemSecondaryAction> */}
                          </ListItemButton>
                        );
                      })}

                    <Divider />
                  </div>
                );
              })}
            </List>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <MapMarkerListPreview
            containerStyled={{
              width: "100%",
              height: "85vh",
            }}
            lat={coodinate?.lat || 0}
            lng={coodinate?.lng || 0}
            data={rows || []}
            selected={selected}
            setOpenProject={setOpenProject}
            setOpenTalent={setOpenTalent}
            setCurrentProjectItem={setCurrentProjectItem}
            setCurrentTalentItem={setCurrentTalentItem}
          />
        </Grid>
      </Grid>
      <ProjectResourceDrawer
        data={currentTalentItem!}
        dataProject={currentProjectItem!}
        open={openTalent}
        setOpen={setOpenTalent}
      />
      <ProjectDrawer
        data={currentProjectItem!}
        open={openProject}
        setOpen={setOpenProject}
      />
    </Box>
  );
};
export default ProjectsMapPage;
