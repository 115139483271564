import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Project } from "../../types/project";
import LoadingButton from "@mui/lab/LoadingButton";
import { useUpdateProjectInternalNotesMutation } from "../../services/project.service";

interface ModalInternalNotesFormProps {
  open: boolean;
  onClose: () => void;
  project: Project | null;
}

const ModalInternalNotesForm = ({
  open,
  onClose,
  project,
}: ModalInternalNotesFormProps) => {
  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const [updateNotes, { isLoading }] = useUpdateProjectInternalNotesMutation();

  const onSubmit = async (payload: any) => {
    try {
      if (!project) return;

      await updateNotes({
        projectId: project?.id,
        internal_notes: payload.internal_notes,
      }).unwrap();
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      setValue("internal_notes", project?.internal_notes);
      clearErrors();
    }
  }, [project, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">Job Internal Notes</DialogTitle>
        <DialogContent>
          <Typography variant="body2">*Not visible to talents</Typography>
          <Controller
            name="internal_notes"
            control={control}
            rules={{ required: true, min: 1 }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Notes"
                value={value}
                fullWidth
                multiline={true}
                rows={8}
                onChange={onChange}
                onBlur={onBlur}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalInternalNotesForm;
