import * as React from "react";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Project } from "../../types/project";
import { AddAlarmOutlined } from "@mui/icons-material";
import { useGetProjectByIdQuery } from "../../services/project.service";
import ModalShiftForm from "./ModalShiftForm";
import { Shift } from "../../types/shift";

const ProjectShiftsPanel = ({ project }: { project: Project }) => {
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useGetProjectByIdQuery(project.id);
  const [shiftModal, setShiftModal] = React.useState<{
    open: boolean;
    data: Shift | null;
  }>({
    open: false,
    data: null,
  });

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Button
        onClick={() => setShiftModal({ open: true, data: null })}
        startIcon={<AddAlarmOutlined />}
        variant="contained"
      >
        Create New Shift
      </Button>
      <ModalShiftForm
        open={shiftModal.open}
        onClose={() => setShiftModal({ open: false, data: null })}
        project={data}
        data={shiftModal.data}
        // onAdd={handleAddTask}
      />
    </Box>
  );
};
export default ProjectShiftsPanel;
