import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import NoResultsOverlay from "../../components/NoResultOverlay";
import { toast } from "react-toastify";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { ShiftTemplate } from "../../types/template";
import { useNavigate } from "react-router-dom";
import {
  useDeleteShiftTemplateMutation,
  useGetShiftTemplatesQuery,
} from "../../services/template.service";
import { Person, Place } from "@mui/icons-material";
import dayjs from "dayjs";
import { formatWithTimezone } from "../../utils/dateHelper";

const myTimezone = dayjs.tz.guess();

const TableToolbar = ({
  onSearch,
  onCreate,
}: {
  onSearch: (query?: string) => void;
  onCreate: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Stack style={{ flex: 1 }} direction="row" justifyContent="flex-end">
        <Button variant="contained" onClick={onCreate}>
          <AddIcon /> Create Shift Template
        </Button>
      </Stack>
    </GridToolbarContainer>
  );
};

const ShiftTemplatesPage = () => {
  useTitle("Shift Templates");
  const navigate = useNavigate();

  const [deleteModal, setDeleteModal] = React.useState<{
    open: boolean;
    row: GridRowModel<ShiftTemplate> | null;
  }>({
    open: false,
    row: null,
  });

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const { data, isLoading } = useGetShiftTemplatesQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    search: searchQuery,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const [doDelete] = useDeleteShiftTemplateMutation();

  const rows: GridRowsProp | undefined = React.useMemo(
    () => data?.data,
    [data]
  );

  const columns: GridColDef[] = React.useMemo(
    () => [
      {
        field: "name",
        headerName: "Shift Name",
        flex: 1,
        minWidth: 300,
        sortingOrder: ["desc", "asc", null],
        filterable: true,
        renderCell: (
          params: GridRenderCellParams<undefined, ShiftTemplate>
        ) => (
          <Box>
            <Link component="button" variant="body2" onClick={() => {}}>
              #{params.row.id} - {params.row.name}
            </Link>
          </Box>
        ),
      },
      {
        field: "hour",
        headerName: "Working Hour",
        headerAlign: "center",
        minWidth: 300,
        sortable: false,
        filterable: false,
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, ShiftTemplate>
        ) =>
          `${formatWithTimezone(
            params.row.start_date,
            params.row.timezone,
            "hh:mmA"
          )} - ${formatWithTimezone(
            params.row.end_date,
            params.row.timezone,
            "hh:mmA z"
          )}`,
      },
      {
        field: "updated_at",
        headerName: "Last Update",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: false,
        align: "center",
        valueGetter: (
          params: GridValueGetterParams<undefined, ShiftTemplate>
        ) => dayjs(params.row.created_at).format("MMM DD, YYYY"),
      },
      {
        field: "control",
        headerName: "",
        headerAlign: "center",
        minWidth: 150,
        sortable: false,
        filterable: false,
        align: "center",
        renderCell: (
          params: GridRenderCellParams<undefined, ShiftTemplate>
        ) => (
          <Stack direction={"row"}>
            <IconButton onClick={() => handleEditRow(params.row)}>
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton onClick={() => handleDeleteRow(params.row)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Stack>
        ),
      },
    ],
    []
  );

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleCreate = React.useCallback(() => {
    navigate(`/jobs/shift-templates/create`);
  }, []);

  const handleDeleteRow = (row: GridRowModel<ShiftTemplate>) => {
    setDeleteModal({ open: true, row });
  };
  const handleEditRow = (row: GridRowModel<ShiftTemplate>) => {
    navigate(`/jobs/shift-templates/${row.id}/edit`);
  };

  const closeDeleteModal = () =>
    setDeleteModal({ ...deleteModal, open: false });

  const handleDelete = async () => {
    if (deleteModal.row?.id) {
      try {
        await doDelete(deleteModal.row.id).unwrap();
        closeDeleteModal();
        toast.success("Successfully deleted");
      } catch (err) {
        closeDeleteModal();
      }
    }
  };

  const CustomToolbar = React.useMemo(
    () => () =>
      <TableToolbar onSearch={handleSearch} onCreate={handleCreate} />,
    [handleSearch]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Paper style={{ height: "85vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[1000]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          // rowHeight={42}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
        <ConfirmDeleteModal
          open={deleteModal.open}
          onClose={closeDeleteModal}
          onDelete={handleDelete}
          message={`Delete '${deleteModal.row?.name}' ?`}
        />
      </Paper>
    </Box>
  );
};
export default ShiftTemplatesPage;
