import { Skill, SkillCategory } from "../types/skill";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_SKILL } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [TAG_TYPE_SKILL] });

export const verticalApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getSkills: builder.query<
      QueryResponse<Skill & { category: SkillCategory }>,
      {
        page: number;
        pageSize: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        withTrashed?: boolean;
      }
    >({
      query: ({
        page,
        pageSize,
        sortField,
        sortMode,
        search,
        withTrashed,
      }) => ({
        url: "api/skills",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode ? `${sortField}|${sortMode}` : `name|asc`,
          withTrashed,
          load: "category",
          search,
        },
      }),
      providesTags: (result) => [{ type: TAG_TYPE_SKILL, id: "LIST" }],
      keepUnusedDataFor: 300,
    }),
  }),
  overrideExisting: false,
});

export const { useGetSkillsQuery } = verticalApi;
