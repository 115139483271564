import * as React from "react";
import { Alert, Box, Chip } from "@mui/material";
import { GoogleMap, OverlayView, useLoadScript } from "@react-google-maps/api";
import { GMAP_API_KEY } from "../configs/api";
import { Project, ProjectResource } from "../types/project";
// import { Talent } from "../types/users";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import FaceIcon from "@mui/icons-material/Face";
import { PersonPinCircle } from "@mui/icons-material";

const containerStyle = {
  width: "100%",
  height: "30vh",
};

const options = {
  //   fullscreenControl: false, // remove the top-right button
  //   mapTypeControl: false, // remove the top-left buttons
  //   streetViewControl: false, // remove the pegman
  zoomControl: true, // remove the bottom-right buttons
  //   scrollwheel: false,
  disableDefaultUI: true,
  clickableIcons: false,
};

const CustomMarker = (props: any) => {
  const { name, type, selected, onClick } = props;

  return (
    <OverlayView {...props}>
      <Box sx={{ marginTop: -2.5, marginLeft: -2.5 }}>
        {/* <Avatar
          alt={name}
          src="/static/images/avatar/2.jpg"
          onClick={onClick}
        /> */}
        {type === "project" ? (
          <Chip
            icon={<HomeRepairServiceIcon />}
            label={`${name}`}
            color={(selected as boolean) === true ? "success" : "warning"}
            onClick={onClick}
          />
        ) : (
          <Chip
            icon={<FaceIcon />}
            label={`${name}`}
            color={(selected as boolean) === true ? "success" : "warning"}
            onClick={onClick}
          />
        )}
      </Box>
    </OverlayView>
  );
};

const MapMarkerListPreview = ({
  lat = 0,
  lng = 0,
  zoom = 14,
  containerStyled = containerStyle,
  data = [],
  selected = 0,
  setOpenProject,
  setOpenTalent,
  setCurrentProjectItem,
  setCurrentTalentItem,
}: {
  lat?: number;
  lng?: number;
  zoom?: number;
  containerStyled?: any;
  data: Array<Project>;
  selected: number;
  setOpenProject: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenTalent: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentProjectItem: React.Dispatch<React.SetStateAction<Project | null>>;
  setCurrentTalentItem: React.Dispatch<
    React.SetStateAction<ProjectResource | null>
  >;
}) => {
  const [mapRef, setMapRef] = React.useState<any>();
  const onMapLoad = React.useCallback((map: any) => {
    // console.log("loaded");
    setMapRef(map);
  }, []);

  const selectedTalentIds = React.useMemo(
    () =>
      data
        ?.find((it) => it.id == selected)
        ?.resources?.map((it) => it.resource?.id) || [],
    [selected, data]
  );

  const onMapCenterChanged = React.useCallback(() => {
    const currentItem = data?.find((item) => item.id === selected);
    if (currentItem) {
      let _coordinates: number[] = [lng, lat];
      if (!!currentItem?.customer) {
        _coordinates = currentItem?.customer?.coordinate?.coordinates;
      } else if (!!currentItem?.location) {
        _coordinates = currentItem?.location?.coordinate?.coordinates || [];
      }

      const bounds = new window.google.maps.LatLngBounds({
        lat: _coordinates[1],
        lng: _coordinates[0],
      });
      let countItem = 0;
      currentItem?.resources?.map((item) => {
        if (!!item.resource?.coordinate) {
          bounds.extend({
            lat: item.resource.coordinate?.coordinates[1],
            lng: item.resource.coordinate?.coordinates[0],
          });
          countItem++;
        }
      });

      if (countItem > 0) {
        mapRef?.fitBounds(bounds);
      } else {
        mapRef?.setCenter({ lat, lng });
        mapRef?.setZoom(14);
      }
    } else {
      mapRef?.setCenter({ lat, lng });
      mapRef?.setZoom(14);
    }
  }, [selected, data, mapRef, lat, lng]);

  React.useEffect(() => {
    // console.log(selected);
    if (mapRef && selected > 0) {
      onMapCenterChanged();
    }
  }, [mapRef, selected]);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GMAP_API_KEY,
    // ...otherOptions
  });

  if (loadError) {
    return (
      <Alert severity="error">Map cannot be loaded right now, sorry.</Alert>
    );
  }

  if (!isLoaded) return <p>Loading...</p>;

  return (
    <Box>
      <GoogleMap
        mapContainerStyle={containerStyled}
        zoom={zoom}
        options={options}
        onLoad={onMapLoad}
        id="map"
      >
        {data?.map((item, index) => {
          let _coordinates: number[] = [];
          if (!!item?.customer) {
            _coordinates = item?.customer?.coordinate?.coordinates;
          } else if (!!item?.location) {
            _coordinates = item?.location?.coordinate?.coordinates || [];
          }

          if (_coordinates.length > 0) {
            return (
              <div key={`#marker-${index}`}>
                <CustomMarker
                  key={`#marker-project-${item?.id}`}
                  name={`#${item?.id}`}
                  type={"project"}
                  selected={item?.id === selected}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  position={{ lat: _coordinates[1], lng: _coordinates[0] }}
                  onClick={() => {
                    setOpenProject(true);
                    setCurrentProjectItem(item!);
                  }}
                />
                {item?.resources
                  ?.filter((it) => !!it.resource?.coordinate)
                  .map((it) => (
                    <CustomMarker
                      key={`#marker-talent-${it?.resource_id}`}
                      name={`${it?.resource?.full_name}`}
                      type={"talent"}
                      selected={selectedTalentIds.includes(it?.resource?.id)}
                      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                      position={{
                        lat: it?.resource?.coordinate?.coordinates[1],
                        lng: it?.resource?.coordinate?.coordinates[0],
                      }}
                      onClick={() => {
                        setOpenTalent(true);
                        setCurrentTalentItem(it!);
                        setCurrentProjectItem(item!);
                      }}
                    />
                  ))}
              </div>
            );
          }
          return null;
        })}
      </GoogleMap>
    </Box>
  );
};
export default React.memo(MapMarkerListPreview);
