import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowsProp,
  GridSortModel,
  GridRowModel,
  GridToolbarContainer,
  GridRowClassNameParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FavoriteIcon from "@mui/icons-material/Favorite";
import StarIcon from "@mui/icons-material/Star";
import {
  Avatar,
  Box,
  Button,
  LinearProgress,
  Link,
  Paper,
  Rating,
  Stack,
  TextField,
} from "@mui/material";
import NoResultsOverlay from "../../components/NoResultOverlay";
import useDebounce from "../../hooks/useDebounce";
import { useTitle } from "../../hooks/useTitle";
import { Talent } from "../../types/users";
import { useNavigate } from "react-router-dom";
import TalentDrawer from "./TalentDrawer";
import { useGetTalentsQuery } from "../../services/talent.service";
import { useAppSelector } from "../../hooks/store";
import { RootState } from "../../app/store";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import ModalTalentFilter from "./ModalTalentFilter";
import { getDistanceBetweenPoints } from "../../utils/mapHelper";
import { filterPrivateTalent } from "../../utils/helper";
import { useCompany } from "../../hooks/useCompany";

const TableToolbar = ({
  onSearch,
  onShowMap,
  onShowFav,
}: {
  onSearch: (query?: string) => void;
  onShowMap: () => void;
  onShowFav: () => void;
}) => {
  const [query, setQuery] = React.useState<string | undefined>(undefined);
  const debounceQuery = useDebounce(query, 500);
  const [showFilter, setShowFilter] = React.useState(false);

  const searchFilter = useAppSelector(
    (state: RootState) => state.ui.talentFilter
  );

  const filterOn = React.useMemo(() => {
    return (
      (Array.isArray(searchFilter.skills) && searchFilter.skills.length > 0) ||
      !!searchFilter.state ||
      !!searchFilter.city ||
      !!searchFilter.country
    );
  }, [searchFilter]);

  React.useEffect(() => {
    onSearch(debounceQuery);
  }, [debounceQuery]);

  return (
    <GridToolbarContainer
      style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 5 }}
    >
      <TextField
        margin="dense"
        label="Enter to search.."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {!!query && (
        <Button variant="text" sx={{ mt: 1 }} onClick={() => setQuery("")}>
          Reset
        </Button>
      )}
      <Button
        variant={filterOn ? "contained" : "text"}
        onClick={() => setShowFilter(true)}
        sx={{ ml: 1, mt: 0 }}
      >
        {filterOn ? <FilterAlt /> : <FilterAltOutlined />} Filter{" "}
        {filterOn ? "ON" : ""}
      </Button>
      <Stack
        spacing={1}
        style={{ flex: 1 }}
        direction="row"
        justifyContent="flex-end"
      >
        <Button variant="contained" onClick={onShowMap}>
          <LocationOnIcon /> Show Map
        </Button>
        <Button variant="contained" onClick={onShowFav}>
          <FavoriteIcon /> Favorites
        </Button>
      </Stack>
      <ModalTalentFilter
        open={showFilter}
        onClose={() => setShowFilter(false)}
      />
    </GridToolbarContainer>
  );
};

const TalentsPage = () => {
  useTitle("Employees");
  const navigate = useNavigate();
  const company = useCompany();

  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(100);
  const [sortModel, setSortModel] = React.useState<GridSortModel>([]);
  const [searchQuery, setSearchQuery] = React.useState<string | undefined>(
    undefined
  );
  const [open, setOpen] = React.useState(false);
  const [currentItem, setCurrentItem] = React.useState<Talent | null>(null);

  const searchFilter = useAppSelector(
    (state: RootState) => state.ui.talentFilter
  );

  const { data, isLoading, isFetching } = useGetTalentsQuery({
    page,
    pageSize,
    sortField: sortModel.length > 0 ? sortModel[0].field : undefined,
    sortMode: sortModel.length > 0 ? sortModel[0].sort : undefined,
    load: "country,photo,skills,languages",
    search: searchQuery,
    ...searchFilter,
  });
  const rowCount = React.useMemo(() => data?.meta.total, [data]);

  const rows: Talent[] | undefined = React.useMemo(
    () => filterPrivateTalent(data?.data, company),
    [data]
  );

  const columns: GridColDef[] = React.useMemo(() => {
    const data: GridColDef[] = [
      {
        field: "avatar",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
          <Avatar
            alt={`${params.row.first_name}`}
            src={`${params.row?.photo?.[0]?.original_url}`}
          />
        ),
      },
      {
        field: "first_name",
        headerName: "Name",
        flex: 1,
        minWidth: 150,
        sortingOrder: ["desc", "asc", null],
        filterable: true,
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setCurrentItem(params.row);
              setOpen(true);
            }}
          >
            {params.row.full_name}
          </Link>
        ),
      },
      {
        field: "rating",
        headerName: "Rating",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        renderCell: (params: GridRenderCellParams<undefined, Talent>) => (
          <Rating value={params.row.total_rating} readOnly precision={0.5} />
        ),
      },
      // {
      //   field: "min_pay",
      //   headerName: "Rate ($)",
      //   minWidth: 150,
      //   sortable: true,
      //   filterable: true,
      //   valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
      //     `${params.row.min_pay || "?"} - ${params.row.max_pay || "?"}`,
      // },
      // {
      //   field: "email",
      //   headerName: "Email",
      //   headerAlign: "center",
      //   minWidth: 200,
      //   sortable: true,
      //   filterable: true,
      //   align: "center",
      // },
      {
        field: "city",
        headerName: "City",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
          `${params.row.city?.toUpperCase()}`,
      },
      {
        field: "state",
        headerName: "State",
        headerAlign: "center",
        minWidth: 150,
        sortable: true,
        filterable: true,
        align: "center",
        valueGetter: (params: GridValueGetterParams<undefined, Talent>) =>
          `${params.row.state?.toUpperCase()}`,
      },
    ];
    return data;
  }, []);

  const handleSearch = React.useCallback((query?: string) => {
    setSearchQuery(query);
  }, []);

  const handleShowMap = React.useCallback(() => {
    navigate(`/talents/map`);
  }, []);

  const handleShowFav = React.useCallback(() => {
    navigate(`/talents/favorites`);
  }, []);

  const CustomToolbar = React.useMemo(
    () => () =>
      (
        <TableToolbar
          onSearch={handleSearch}
          onShowMap={handleShowMap}
          onShowFav={handleShowFav}
        />
      ),
    [handleSearch, handleShowMap, handleShowFav]
  );

  return (
    <Box sx={{ bgcolor: "background.paper", width: "auto" }}>
      <Paper style={{ height: "85vh" }}>
        <DataGrid
          rows={rows || []}
          columns={columns}
          paginationMode="server"
          rowCount={rowCount}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          pageSize={pageSize}
          rowsPerPageOptions={[1000]}
          onPageSizeChange={(newSize) => setPageSize(newSize)}
          sortModel={sortModel}
          sortingMode="server"
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isLoading || isFetching}
          components={{
            LoadingOverlay: LinearProgress,
            NoResultsOverlay: NoResultsOverlay,
            Toolbar: CustomToolbar,
          }}
          disableSelectionOnClick
          disableColumnFilter
          rowHeight={72}
          density="compact"
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#f5f5f5",
              borderTop: "1px solid #ddd",
            },
          }}
        />
      </Paper>

      <TalentDrawer data={currentItem!} open={open} setOpen={setOpen} />
    </Box>
  );
};
export default TalentsPage;
