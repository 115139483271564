import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  FilePresent,
  RemoveCircleOutlineOutlined,
  UploadFile,
} from "@mui/icons-material";
import {
  useUploadProjectDocumentMutation,
  useGetProjectsQuery,
} from "../../services/project.service";
import { useFilePicker } from "use-file-picker";
import ProjectSelector from "./ProjectSelector";
import { toast } from "react-toastify";

import { useCompany } from "../../hooks/useCompany";
import { CompanyStatus } from "../../types/company";

interface ModalUploadDocumentsProps {
  open: boolean;
  onClose: () => void;
}

const ModalUploadDocuments = ({ open, onClose }: ModalUploadDocumentsProps) => {
  const {
    data: projects,
    isLoading,
    isFetching,
    refetch,
  } = useGetProjectsQuery(
    {
      page: 0,
      pageSize: 1000,
      statuses: "draft,in-progress,publish",
      load: "",
      sortField: "created_at",
      sortMode: "desc",
    },
    {
      skip: !open,
    }
  );
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const [progress, setProgress] = React.useState<number>(0);
  const [waiting, setWaiting] = React.useState<boolean>(false);
  const [currentlyUploading, setCurrentlyUploading] = React.useState<number>(0);
  const [
    openFileSelector,
    { plainFiles, loading, errors: uploadErrors, clear },
  ] = useFilePicker({
    multiple: false,
    accept: [
      ".doc",
      ".docx",
      ".pdf",
      ".txt",
      ".jpeg",
      ".jpg",
      ".png",
      ".gif",
      ".pptx",
      ".zip",
      ".xls",
      "xlsx",
      ".csv",
    ],
    maxFileSize: 50,
    readFilesContent: false,
  });
  const company = useCompany();
  const [uploadDoc, { isLoading: uploading }] =
    useUploadProjectDocumentMutation();
  const [file, setFile] = React.useState<File | null>();

  React.useEffect(() => {
    uploadErrors?.[0]?.fileSizeTooSmall &&
      toast.error("File size is too small!");
    uploadErrors?.[0]?.fileSizeToolarge &&
      toast.error("File size is too large!");
    uploadErrors?.[0]?.readerError &&
      toast.error("Problem occured while reading file!");
    uploadErrors?.[0]?.maxLimitExceeded && toast.error("Too many files");
    uploadErrors?.[0]?.minLimitNotReached && toast.error("Not enought files");
  }, [uploadErrors]);

  function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async function asyncUploadDoc() {
    setWaiting(true);
    if (file) {
      for (let i = 0; i < selectedIds.length; i++) {
        setCurrentlyUploading(selectedIds[i]);
        await timeout(500);
        await uploadDoc({
          projectId: selectedIds[i],
          file,
          refetch: false,
        }).unwrap();

        setProgress((i / selectedIds.length) * 100);
      }
    }
  }

  const onSubmit = () => {
    if (!file) {
      toast.error("Please choose a file");
      return;
    }
    asyncUploadDoc()
      .then((res) => {
        toast.success("Upload Successful");
        onClose();
      })
      .catch((err) => {
        toast.error(err.message);
        clear();
        onClose();
      })
      .finally(() => {
        setWaiting(false);
      });
  };

  React.useEffect(() => {
    if (plainFiles?.length > 0) {
      setFile(plainFiles[0]);
    }
  }, [plainFiles]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      style={{ overflowY: "hidden" }}
    >
      <DialogTitle id="alert-dialog-title">
        Upload documents and select the jobs where you want it to be attached
      </DialogTitle>
      <Box sx={{ paddingRight: 3, paddingLeft: 3, paddingBottom: 3 }}>
        <Card>
          <CardHeader title="Job Documents" />
          <CardContent>
            {file && (
              <List>
                <ListItem
                  key={file.name}
                  secondaryAction={
                    <IconButton
                      onClick={() => {
                        setFile(null);
                      }}
                    >
                      <RemoveCircleOutlineOutlined
                        fontSize="small"
                        color="error"
                      />
                    </IconButton>
                  }
                >
                  <ListItemIcon>
                    <FilePresent />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${file.name || "n/a"}`}
                    primaryTypographyProps={{
                      fontWeight: "500",
                      fontSize: 16,
                    }}
                    // secondary={
                    //   <Link href={file} target="_blank">
                    //     View
                    //   </Link>
                    // }
                    sx={{ flex: 1 }}
                  />
                </ListItem>
              </List>
            )}
            <LoadingButton
              variant="contained"
              size="small"
              onClick={() => openFileSelector()}
              disabled={company?.status != CompanyStatus.ACTIVE}
              startIcon={<UploadFile />}
              sx={{ ml: 1 }}
              loading={uploading || waiting}
            >
              Choose file
            </LoadingButton>
            {waiting && (
              <div>
                <Box sx={{ ml: 1, mt: 2 }}>
                  Attaching file to project with ID number {currentlyUploading}
                </Box>
                <LinearProgress
                  sx={{ ml: 1, mt: 1 }}
                  variant="determinate"
                  value={progress}
                />
              </div>
            )}
          </CardContent>
        </Card>
      </Box>
      <Divider />
      <DialogContent>
        <Card sx={{ mt: 1 }}>
          {isLoading && (
            <Box sx={{ alignItems: "center", padding: 2 }}>
              <Typography variant="body1">Loading jobs...</Typography>
            </Box>
          )}
          <ProjectSelector
            selected={selectedIds}
            setSelected={setSelectedIds}
            projects={projects?.data || []}
          />
        </Card>
      </DialogContent>
      {/* <Box sx={{ paddingRight: 3, paddingLeft: 3, paddingBottom: 3 }}>
        <Card sx={{ mt: 1 }}>
          <Divider />
          <ProjectSelector
            selected={selectedIds}
            setSelected={setSelectedIds}
            projects={projects?.data}
          />
        </Card>
      </Box> */}
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={false}
          onClick={() => {
            onSubmit();
          }}
        >
          Upload
          {/* {!data ? "Create" : "Update"} */}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
export default ModalUploadDocuments;
