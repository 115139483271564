import { Customer } from "../types/customer";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_CUSTOMER } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_CUSTOMER],
});

export const customerApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getCustomers: builder.query<
      QueryResponse<Customer>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ page = 0, pageSize = 100, sortField, sortMode, search }) => ({
        url: `customers`,
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `first_name|asc`,
          // withTrashed: true,
          search,
          // load: "country",
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_CUSTOMER, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_CUSTOMER, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_CUSTOMER, id })),
        ];
      },
    }),
    getCustomerById: builder.query<Customer, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `customers/${id}`,
        method: "GET",
        params: {
          load: "country",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_CUSTOMER, id }],
    }),
    addCustomer: builder.mutation<Customer, Omit<Customer, "id">>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `customers`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_CUSTOMER, id: "PARTIAL-LIST" },
      ],
    }),
    editCustomer: builder.mutation<
      Customer,
      Partial<Customer> & Pick<Customer, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `customers/${body.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_CUSTOMER, id: data.id },
        { type: TAG_TYPE_CUSTOMER, id: "PARTIAL-LIST" },
      ],
    }),
    deleteCustomer: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `customers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_CUSTOMER, id },
        { type: TAG_TYPE_CUSTOMER, id: "PARTIAL-LIST" },
      ],
    }),
    restoreCustomer: builder.mutation<void, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `customers/${id}/restore`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_CUSTOMER, id },
        { type: TAG_TYPE_CUSTOMER, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetCustomersQuery,
  useGetCustomerByIdQuery,
  useAddCustomerMutation,
  useEditCustomerMutation,
  useDeleteCustomerMutation,
  useRestoreCustomerMutation,
} = customerApi;
