import axios from "axios";

const http = axios.create({
  timeout: 30000,
  withCredentials: false,
  headers: {},
});

http.interceptors.request.use(
  (request) => {
    console.log("REQ:", request.url + " -> " + request.data);
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    //console.log('RESP:', response.data);
    return response;
  },
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      if (error.response.status === 401) {
        console.log("Response 401!");
        // TODO if API decided to have authentication mechanism
      } else {
        const { data } = error.response;
        console.log("ERROR response:", data);
        let message = error.message;

        if (!!data.errorMessage) message = data.errorMessage;
        else if (typeof data === "string" && data.length < 255) message = data;
        else if (Array.isArray(data)) {
          let message = data?.map((d) => d.message).join("\n");
          return Promise.reject(message);
        }
        return Promise.reject("[" + error.response.status + "] " + message);
      }
    } else if (error.request) {
      // The request was made but no response was received
      return Promise.reject(
        "There was a problem connecting to the server. Please check your internet connection."
      );
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject(error.message);
    }
  }
);

export default http;
