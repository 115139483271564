import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import { Controller, useForm } from "react-hook-form";
import { Project } from "../../types/project";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRescheduleProjectMutation } from "../../services/project.service";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SelectReason from "../../components/SelectReason";

interface ModalStartDateFormProps {
  open: boolean;
  onClose: () => void;
  data: Project | null;
}

const ModalStartDateForm = ({
  open,
  onClose,
  data,
}: ModalStartDateFormProps) => {
  const [edit, { isLoading: updating }] = useRescheduleProjectMutation();

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    setError,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      payload.start_date = dayjs(payload.start_date).format("YYYY-MM-DD HH:mm");

      payload.id = data?.id!;
      if (!!payload.reason) payload.reason_schedule = payload.reason;
      await edit(payload).unwrap();
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (!open) return;

    setValue("start_date", data?.start_date || null);

    clearErrors();
  }, [data, open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">Edit Start Time</DialogTitle>
        <DialogContent>
          <Controller
            name="start_date"
            control={control}
            defaultValue={null}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DesktopDateTimePicker
                sx={{ mt: 1, width: "50%" }}
                onChange={onChange}
                value={dayjs(value)}
                label="Start Working Time"
                minutesStep={5}
                onError={() => {
                  setError("start_date", {
                    type: "custom",
                    message: "Invalid",
                  });
                }}
                onAccept={() => clearErrors("start_date")}
                slotProps={{
                  textField: {
                    helperText: !!errors.start_date && "This field is required",
                  },
                }}
              />
            )}
          />
          <Controller
            name="reason"
            control={control}
            // rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <SelectReason
                label="Select Reschedule Reason"
                onChange={onChange}
                error={!!errors.reason}
                helperText={!!errors.reason && "This field is required"}
                type="reschedule-job"
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton type="submit" variant="contained" loading={updating}>
            Update
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalStartDateForm;
