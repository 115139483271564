import * as React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useGetBusinessVerticalsQuery } from "../services/vertical.service";
import { BusinessVertical } from "../types/vertical";

const SelectVertical = ({
  onChange,
  sx,
  value,
  error,
  helperText,
  label = "Industry",
  size,
}: {
  onChange: (arg?: number) => void;
  sx?: any;
  value: number;
  error?: boolean;
  helperText?: React.ReactNode;
  label?: string;
  size?: "small" | "medium";
}) => {
  const { data: verticals, isLoading } = useGetBusinessVerticalsQuery();

  const selected = React.useMemo(
    () => verticals?.find((it) => it.id == value),
    [verticals, value]
  );

  return (
    <Autocomplete
      fullWidth
      options={verticals || []}
      value={selected || null}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search..."
          size={size}
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue: BusinessVertical | null) => {
        onChange(newValue?.id);
      }}
      loading={isLoading}
    />
  );
};
export default SelectVertical;
