import { Divider, Stack, Typography, Box } from "@mui/material";
import * as React from "react";

const TitleDivider = ({ title }: { title: string }) => {
  return (
    <Stack direction={"row"} alignItems="center" sx={{ mt: 2 }}>
      <Typography sx={{ fontSize: 16, fontWeight: "medium" }}>
        {title}
      </Typography>
      <Box sx={{ flex: 1, ml: 2 }}>
        <Divider light={true} />
      </Box>
    </Stack>
  );
};
export default TitleDivider;
