import { BusinessVertical } from "../types/vertical";
import { baseApi } from "./base";
import { TAG_TYPE_VERTICAL } from "./tags";

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_VERTICAL],
});

export const verticalApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessVerticals: builder.query<Array<BusinessVertical>, void>({
      query: () => ({
        url: "api/business-verticals",
        method: "GET",
        params: {
          sort: `name|asc`,
        },
      }),
      providesTags: (result) => [{ type: TAG_TYPE_VERTICAL, id: "LIST" }],
      keepUnusedDataFor: 300,
    }),
  }),
  overrideExisting: false,
});

export const { useGetBusinessVerticalsQuery } = verticalApi;
