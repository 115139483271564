import dayjs from "dayjs";
import { ProjectTask } from "../types/project";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_PROJECT, TAG_TYPE_PROJECT_TASK } from "./tags";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

//@ts-ignore
const myTimezone = dayjs.tz.guess();

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_PROJECT_TASK, TAG_TYPE_PROJECT],
});

export const projectTaskApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjectTasks: builder.query<
      QueryResponse<ProjectTask>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        project?: number;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ page = 0, pageSize = 100, sortField, sortMode, project }) => ({
        url: `tasks`,
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `first_name|asc`,
          project,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_PROJECT_TASK, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_PROJECT_TASK, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_PROJECT_TASK, id })),
        ];
      },
    }),
    addProjectTask: builder.mutation<
      ProjectTask,
      Omit<ProjectTask, "id"> & { project: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `tasks`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT_TASK, id: "PARTIAL-LIST" },
        { type: TAG_TYPE_PROJECT, id: data.project_id },
      ],
    }),
    editProjectTask: builder.mutation<
      ProjectTask,
      Partial<ProjectTask> & Pick<ProjectTask, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `tasks/${body.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT_TASK, id: data.id },
        { type: TAG_TYPE_PROJECT_TASK, id: "PARTIAL-LIST" },
        { type: TAG_TYPE_PROJECT, id: data.project_id },
      ],
    }),
    deleteProjectTask: builder.mutation<void, { id: number; project: number }>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ id, project }) => ({
        url: `tasks/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT_TASK, id: data.id },
        { type: TAG_TYPE_PROJECT_TASK, id: "PARTIAL-LIST" },
        { type: TAG_TYPE_PROJECT, id: data.project },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectTasksQuery,
  useAddProjectTaskMutation,
  useEditProjectTaskMutation,
  useDeleteProjectTaskMutation,
} = projectTaskApi;
