import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Chip,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Link,
  ListItemIcon,
  IconButton,
} from "@mui/material";
import { NegotiationStatus, Project, ProjectStatus } from "../../types/project";
import { useNavigate } from "react-router-dom";
import MapPreview from "../../components/MapPreview";
import {
  AssignmentOutlined,
  ContactPhone,
  ContentCopy,
  EditOutlined,
  EmailOutlined,
  ExpandMore,
  FilePresent,
  RemoveCircleOutlineOutlined,
  UploadFile,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { nl2br } from "../../utils/helper";
// import EditIcon from "@mui/icons-material/Edit";
import ModalStartDateForm from "./ModalStartDateForm";
import {
  useDeleteProjectDocumentMutation,
  useUploadProjectDocumentMutation,
} from "../../services/project.service";
import { useFilePicker } from "use-file-picker";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import ModalInternalNotesForm from "./ModalInternalNotesForm";

import { useCompany } from "../../hooks/useCompany";
import { CompanyStatus } from "../../types/company";
import { formatWithTimezone } from "../../utils/dateHelper";

const ProjectDetailsPanel = ({ data }: { data?: Project }) => {
  const navigate = useNavigate();

  const [uploadDoc, { isLoading: uploading }] =
    useUploadProjectDocumentMutation();
  const [deleteDoc] = useDeleteProjectDocumentMutation();
  const company = useCompany();

  const [openFileSelector, { plainFiles, loading, errors, clear }] =
    useFilePicker({
      multiple: false,
      accept: [
        ".doc",
        ".docx",
        ".pdf",
        ".txt",
        ".jpeg",
        ".jpg",
        ".png",
        ".gif",
        ".pptx",
        ".zip",
        ".xls",
        "xlsx",
        ".csv",
      ],
      maxFileSize: 50,
      readFilesContent: false,
    });

  const [formStartDateModal, setFormStartDateModal] = React.useState<{
    open: boolean;
    data: Project | null;
  }>({
    open: false,
    data: null,
  });
  const [formInternalNotesModal, setFormInternalNotesModal] = React.useState<{
    open: boolean;
    data: Project | null;
  }>({
    open: false,
    data: null,
  });

  const [lng, lat] = React.useMemo(() => {
    if (!!data?.customer) {
      return data?.customer?.coordinate?.coordinates || [];
    } else if (!!data?.location) {
      return data?.location?.coordinate?.coordinates || [];
    } else return [];
  }, [data]);

  const resource = React.useMemo(() => {
    const res = data?.resources?.filter(
      (it) =>
        it.status == NegotiationStatus.HIRED ||
        it.status == NegotiationStatus.COMPLETED
    )?.[0];
    return res;
  }, [data]);

  const _uploadDoc = async (file: File) => {
    try {
      await uploadDoc({ projectId: data?.id!, file }).unwrap();
      clear();
    } catch (err: any) {
      toast.error(err.message);
      clear();
    }
  };

  React.useEffect(() => {
    if (plainFiles?.length > 0) {
      const file = plainFiles[0];
      _uploadDoc(file);
    }
  }, [plainFiles]);

  React.useEffect(() => {
    errors?.[0]?.fileSizeTooSmall && toast.error("File size is too small!");
    errors?.[0]?.fileSizeToolarge && toast.error("File size is too large!");
    errors?.[0]?.readerError &&
      toast.error("Problem occured while reading file!");
    errors?.[0]?.maxLimitExceeded && toast.error("Too many files");
    errors?.[0]?.minLimitNotReached && toast.error("Not enought files");
  }, [errors]);

  return (
    <Box>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={8}>
          <Card sx={{ mt: 0 }}>
            <CardHeader title="Scope of Work" />
            <CardContent>
              <Typography variant="body2">
                {nl2br(data?.description)}
              </Typography>
            </CardContent>
            <Divider />
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Confidential Information" />
            <CardContent>
              <Typography variant="body2">
                {nl2br(data?.confidential_information) || "n/a"}
              </Typography>
            </CardContent>
            <Divider />
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Job Documents" />
            <CardContent>
              {data?.documents?.length == 0 && (
                <Typography variant="caption">
                  No documents specified
                </Typography>
              )}
              <List>
                {data?.documents?.map((doc) => (
                  <ListItem
                    key={doc.id}
                    secondaryAction={
                      <IconButton
                        onClick={() =>
                          deleteDoc({ projectId: data?.id!, id: doc.id })
                        }
                      >
                        <RemoveCircleOutlineOutlined
                          fontSize="small"
                          color="error"
                        />
                      </IconButton>
                    }
                  >
                    <ListItemIcon>
                      <FilePresent />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${doc.name || "n/a"}`}
                      primaryTypographyProps={{
                        fontWeight: "500",
                        fontSize: 16,
                      }}
                      secondary={
                        <Link href={doc.original_url} target="_blank">
                          View
                        </Link>
                      }
                      sx={{ flex: 1 }}
                    />
                  </ListItem>
                ))}
              </List>
              <LoadingButton
                variant="outlined"
                size="small"
                onClick={() => openFileSelector()}
                disabled={company?.status != CompanyStatus.ACTIVE}
                startIcon={<UploadFile />}
                sx={{ ml: 1 }}
                loading={uploading}
              >
                Upload
              </LoadingButton>
            </CardContent>
            <Divider />
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Start Time" />
            <CardContent>
              <Typography variant="body1">
                {/* {formatWithTimezone(data?.start_date, data?.timezone)}{" "} */}
                {!!resource &&
                (data?.status == ProjectStatus.SCHEDULED ||
                  data?.status == ProjectStatus.IN_PROGRESS ||
                  data?.status == ProjectStatus.COMPLETED)
                  ? formatWithTimezone(resource?.start_date, resource?.timezone)
                  : formatWithTimezone(data?.start_date, data?.timezone)}
              </Typography>
              {!!data?.reason_schedule && (
                <Typography variant="caption" sx={{ color: "text.disabled" }}>
                  Reason schedule: {data?.reason_schedule || "n/a"}
                </Typography>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Tasks" />
            <CardContent>
              {data?.tasks?.length == 0 && (
                <Typography variant="caption">No tasks specified</Typography>
              )}
              {data?.tasks?.map((it, idx) => (
                <Accordion key={idx}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <AssignmentOutlined sx={{ mr: 2 }} />
                    <Typography>{it.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="caption">
                      {it.description || "n/a"}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Requirements" />
            <CardContent>
              <Typography variant="body2" fontWeight={500}>
                Skills
              </Typography>
              <Box sx={{ mt: 1 }}>
                {data?.skills?.length == 0 ? (
                  <Typography sx={{ fontSize: 12, color: "text.disabled" }}>
                    Not Required
                  </Typography>
                ) : (
                  data?.skills?.map((it) => (
                    <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                  ))
                )}
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Additional Settings" />
            <CardContent>
              <Typography variant="body2">
                Photo Required → {!!data?.is_photo_required ? "Yes" : "No"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader title="Work Site" />
            <CardContent sx={{ p: 0 }}>
              <MapPreview lat={lat} lng={lng} />
            </CardContent>
            <Divider />
            <CardContent>
              {!!data?.location && (
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {data?.location?.name}
                  </Typography>
                  {data?.location.address_1}, {data?.location.city},{" "}
                  {data?.location.state} {data?.location.zip}
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhone
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.location?.primary_contact_name} -{" "}
                    {data?.location?.primary_contact_phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.location?.primary_contact_email}
                  </Stack>
                </Box>
              )}
              {!!data?.customer && (
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {data?.customer?.first_name}
                  </Typography>
                  {data?.customer.physical_address_1}, {data?.customer.city},{" "}
                  {data?.customer.state} {data?.customer.zip}
                  <br />
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhone
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.customer?.phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.customer?.email}
                  </Stack>
                </Box>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Manager" />
            <CardContent>
              <Typography variant="body2">
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {data?.manager?.first_name} {data?.manager?.last_name}
                  </Typography>
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhone
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.manager?.phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.manager?.email}
                  </Stack>
                </Box>
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader
              title="Ticket Notes"
              action={
                <IconButton
                  size="small"
                  onClick={() =>
                    setFormInternalNotesModal({ open: true, data: data! })
                  }
                >
                  <EditOutlined />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="body2">
                {nl2br(data?.internal_notes || "n/a")}
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Metadata" />
            <CardContent>
              <Typography variant="body2">
                Created At →
                {dayjs(data?.created_at).format("MMM DD, YYYY hh:mm A z")}
                <br />
                Last Updated →
                {dayjs(data?.updated_at).format("MMM DD, YYYY hh:mm A z")}
              </Typography>
            </CardContent>
          </Card>
          <Box sx={{ mt: 1 }}>
            <Button
              variant="text"
              onClick={() => navigate(`/jobs/${data?.id}/template/create`)}
              startIcon={<ContentCopy />}
            >
              Create Template From This Job
            </Button>
          </Box>
        </Grid>
      </Grid>
      <ModalStartDateForm
        open={formStartDateModal.open}
        onClose={() => setFormStartDateModal({ open: false, data: null })}
        data={formStartDateModal.data}
      />
      <ModalInternalNotesForm
        open={formInternalNotesModal.open}
        onClose={() => setFormInternalNotesModal({ open: false, data: null })}
        project={formInternalNotesModal.data}
      />
    </Box>
  );
};
export default ProjectDetailsPanel;
