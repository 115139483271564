import * as React from "react";
import { Box, Autocomplete, TextField } from "@mui/material";
import { Reason, ReasonType } from "../types/reason";
import { useGetReasonsByTypeQuery } from "../services/reason.service";

const SelectReason = ({
  onChange,
  sx,
  label = "Reason",
  error,
  helperText,
  type,
}: {
  onChange: (arg?: string) => void;
  sx?: any;
  label?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  type: ReasonType;
}) => {
  const {
    data: reasons,
    isLoading,
    isFetching,
  } = useGetReasonsByTypeQuery(type);

  return (
    <Autocomplete
      fullWidth
      options={reasons || []}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder="Search..."
          sx={sx}
          error={error}
          helperText={helperText}
        />
      )}
      onChange={(event: any, newValue: Reason | null) => {
        onChange(newValue?.name);
      }}
      loading={isLoading || isFetching}
    />
  );
};
export default SelectReason;
