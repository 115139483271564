import dayjs from "dayjs";
import { Project } from "../types/project";
import { isFile } from "../utils/helper";
import { baseApi, QueryResponse } from "./base";
import { TAG_TYPE_PROJECT } from "./tags";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);

//@ts-ignore
// const myTimezone = dayjs.tz.guess();

const apiWithTag = baseApi.enhanceEndpoints({
  addTagTypes: [TAG_TYPE_PROJECT],
});

export const projectApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<
      QueryResponse<Project>,
      {
        page?: number;
        pageSize?: number;
        sortField?: string;
        sortMode?: string | null;
        search?: string;
        load?: string;
        status?: string;
        statuses?: string;
      }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({
        page = 0,
        pageSize = 25,
        sortField,
        sortMode,
        search,
        load = "customer,manager,location,resources.resource,resources.reportProgresses",
        status,
        statuses,
      }) => ({
        url: "projects",
        method: "GET",
        params: {
          per_page: pageSize,
          page: page + 1,
          sort:
            !!sortField && !!sortMode
              ? `${sortField}|${sortMode}`
              : `start_date|desc`,
          // : `first_name|asc`,
          // withTrashed: true,
          search,
          load,
          status,
          statuses,
        },
      }),
      providesTags: (result) => {
        const data = result?.data;
        if (!data) return [{ type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" }];
        return [
          { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
          ...data?.map(({ id }) => ({ type: TAG_TYPE_PROJECT, id })),
        ];
      },
    }),
    getProjectById: builder.query<Project, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => ({
        url: `projects/${id}`,
        method: "GET",
        params: {
          load: "customer,manager,location,skills,certifications,tasks,resources,resources.reportProgresses,documents,verticals,chats",
        },
      }),
      providesTags: (result, error, id) => [{ type: TAG_TYPE_PROJECT, id }],
    }),
    addProject: builder.mutation<Project, Omit<Project, "id">>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `projects`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
      ],
    }),
    editProject: builder.mutation<
      Project,
      Partial<Project> & Pick<Project, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        console.log("body", body);
        return {
          url: `projects/${body.id}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT, id: data.id },
        { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
      ],
    }),
    publishProject: builder.mutation<Project, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => {
        return {
          url: `projects/${id}/activated`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_PROJECT, id: id },
        { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
      ],
    }),
    cancelProject: builder.mutation<
      Project,
      { projectId: number; reason: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, reason }) => {
        return {
          url: `projects/${projectId}/canceled`,
          method: "PUT",
          body: {
            canceled_reason: reason,
          },
        };
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG_TYPE_PROJECT, id: payload.projectId },
        { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
      ],
    }),
    submitBudgetApprovalProject: builder.mutation<Project, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => {
        return {
          url: `projects/${id}/submitted`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_PROJECT, id: id },
        { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
      ],
    }),
    completeProject: builder.mutation<Project, number>({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (id) => {
        return {
          url: `projects/${id}/completed`,
          method: "PUT",
        };
      },
      invalidatesTags: (result, error, id) => [
        { type: TAG_TYPE_PROJECT, id: id },
        { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
      ],
    }),
    rescheduleProject: builder.mutation<
      Project,
      Partial<Project> & Pick<Project, "id">
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: (body) => {
        return {
          url: `projects/${body.id}/reschedule`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT, id: data.id },
        { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
      ],
    }),
    uploadProjectDocument: builder.mutation<
      void,
      { projectId: number; file: File; refetch?: boolean }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, file, refetch }) => {
        let formData = new FormData();
        if (!!file) formData.append("document", file as any);
        return {
          url: `project-documents/${projectId}`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, data) => {
        if (data.refetch === false) {
          return [{ type: "" }];
        }
        return [{ type: TAG_TYPE_PROJECT, id: data.projectId }];
      },
    }),
    deleteProjectDocument: builder.mutation<
      void,
      { projectId: number; id: number }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, id }) => {
        return {
          url: `project-documents/${projectId}/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, data) => [
        { type: TAG_TYPE_PROJECT, id: data.projectId },
      ],
    }),
    updateProjectInternalNotes: builder.mutation<
      Project,
      { projectId: number; internal_notes: string }
    >({
      extraOptions: {
        isCompanyApi: true,
      },
      query: ({ projectId, internal_notes }) => {
        return {
          url: `projects/${projectId}/internal-notes`,
          method: "PUT",
          body: {
            internal_notes,
          },
        };
      },
      invalidatesTags: (result, error, { projectId }) => [
        { type: TAG_TYPE_PROJECT, id: projectId },
        { type: TAG_TYPE_PROJECT, id: "PARTIAL-LIST" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectsQuery,
  useGetProjectByIdQuery,
  useAddProjectMutation,
  useEditProjectMutation,
  usePublishProjectMutation,
  useCancelProjectMutation,
  useSubmitBudgetApprovalProjectMutation,
  useCompleteProjectMutation,
  useRescheduleProjectMutation,
  useUploadProjectDocumentMutation,
  useDeleteProjectDocumentMutation,
  useUpdateProjectInternalNotesMutation,
} = projectApi;
