import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "@mui/lab/LoadingButton";
import SelectCountry from "../../components/SelectCountry";
import SelectMapAddress from "../../components/SelectMapAddress";
import { AddressComponent } from "../../types/common";
import { useGetCountriesQuery } from "../../services/country.service";
import TitleDivider from "../../components/TitleDivider";
import PinDropIcon from "@mui/icons-material/PinDrop";
import SearchIcon from "@mui/icons-material/Search";
import CoordinateInfo from "../../components/CoordinateInfo";
import {
  useAddCustomerMutation,
  useEditCustomerMutation,
} from "../../services/customer.service";
import { Customer } from "../../types/customer";
import { ConstructionOutlined } from "@mui/icons-material";

interface ClientModalFormProps {
  open: boolean;
  onClose: () => void;
  onCreated?: (id: number) => void;
  data: Customer | null;
}

const ClientModalForm = ({
  open,
  onClose,
  onCreated,
  data,
}: ClientModalFormProps) => {
  const [add, { isLoading: adding }] = useAddCustomerMutation();
  const [edit, { isLoading: editing }] = useEditCustomerMutation();
  const { data: countries } = useGetCountriesQuery();
  const [showAddress, setShowAddress] = React.useState(false);

  const {
    handleSubmit,
    clearErrors,
    control,
    setValue,
    setError,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (payload: any) => {
    try {
      if (!payload.country) {
        setError("country", { type: "custom", message: "Invalid" });
        return;
      }
      if (!data) {
        const cust = await add(payload).unwrap();
        if (onCreated) {
          onCreated(cust.id);
        }
      } else {
        if (!payload.password) delete payload.password;
        payload.id = data?.id!;
        await edit(payload).unwrap();
      }
      onClose();
    } catch (err) {
      onClose();
    }
  };

  React.useEffect(() => {
    if (!open) return;

    setValue("first_name", data?.first_name);
    setValue("phone_number", data?.phone_number || "");
    setValue("email", data?.email || "");
    setValue("physical_address_1", data?.physical_address_1);
    setValue("physical_address_2", data?.physical_address_2);
    setValue("city", data?.city);
    setValue("state", data?.state);
    setValue("zip", data?.zip);
    setValue("country", data?.country_id || null);
    const [lng, lat] = data?.coordinate?.coordinates || [];
    setValue("lat", lat || 0);
    setValue("lng", lng || 0);
    setShowAddress(!!lat && !!lng);

    clearErrors();
  }, [data, open]);

  const handleMapAddressChange = (
    address: AddressComponent,
    lat?: number,
    lng?: number
  ) => {
    setValue(
      "physical_address_1",
      `${address.street} ${address.street_number}`
    );
    setValue("city", address.city);
    setValue("state", address.state);
    setValue("zip", address.postal_code);
    setValue("lat", lat);
    setValue("lng", lng);
    const found = countries?.find(
      (it) => it.code.toLowerCase() == address.country.toLowerCase()
    );
    setValue("country", found?.id);
    setShowAddress(true);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">
          {!data ? "Create" : "Edit"} Customer
        </DialogTitle>
        <DialogContent>
          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <TextField
                label="Customer name"
                InputLabelProps={{ shrink: !!value }}
                value={value}
                fullWidth
                onChange={onChange}
                onBlur={onBlur}
                error={!!errors.first_name}
                helperText={!!errors.first_name && "This field is required"}
              />
            )}
          />
          <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
            <Controller
              name="phone_number"
              control={control}
              rules={{ required: true, minLength: 6 }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Phone number"
                  value={value}
                  fullWidth
                  sx={{ mt: 0 }}
                  onChange={onChange}
                  onBlur={onBlur}
                  type="number"
                  error={!!errors.phone_number}
                  helperText={!!errors.phone_number && "This field is required"}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Entered value does not match email format",
                },
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <TextField
                  label="Email"
                  value={value}
                  fullWidth
                  onChange={onChange}
                  onBlur={onBlur}
                  type="email"
                  error={!!errors.email}
                  helperText={!!errors.email && "This field is required"}
                />
              )}
            />
          </Stack>
          {!showAddress && (
            <SelectMapAddress
              onChange={handleMapAddressChange}
              error={!!errors.country}
              helperText={!!errors.country && "This field is required"}
            />
          )}
          {showAddress && (
            <Box>
              <TitleDivider title="Address" />
              <Button
                onClick={() => {
                  setValue("country", null); //reset
                  setShowAddress(false);
                }}
                size="small"
                startIcon={<SearchIcon />}
              >
                Search New Address
              </Button>
              <Controller
                name="physical_address_1"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Address 1"
                    InputLabelProps={{ shrink: !!value }}
                    value={value}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!errors.physical_address_1}
                    helperText={
                      !!errors.physical_address_1 && "This field is required"
                    }
                  />
                )}
              />
              <Controller
                name="physical_address_2"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <TextField
                    label="Address 2"
                    InputLabelProps={{ shrink: !!value }}
                    value={value}
                    fullWidth
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <SelectCountry
                    onChange={onChange}
                    value={value}
                    error={!!errors.country}
                    helperText={!!errors.country && "This field is required"}
                  />
                )}
              />
              <Stack direction={"row"} spacing={2} sx={{ mt: 2 }}>
                <Controller
                  name="city"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label="City"
                      InputLabelProps={{ shrink: !!value }}
                      value={value}
                      fullWidth
                      sx={{ mt: 0 }}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.city}
                      helperText={!!errors.city && "This field is required"}
                    />
                  )}
                />
                <Controller
                  name="state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label="State"
                      InputLabelProps={{ shrink: !!value }}
                      value={value}
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.state}
                      helperText={!!errors.state && "This field is required"}
                    />
                  )}
                />
                <Controller
                  name="zip"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <TextField
                      label="Zip"
                      InputLabelProps={{ shrink: !!value }}
                      value={value}
                      fullWidth
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!errors.zip}
                      helperText={!!errors.zip && "This field is required"}
                    />
                  )}
                />
              </Stack>
              <CoordinateInfo lat={watch("lat")} lng={watch("lng")} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={adding || editing}
          >
            {!data ? "Create" : "Update"}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ClientModalForm;
