import * as React from "react";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  LinearProgress,
  Typography,
  Chip,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useTitle } from "../../hooks/useTitle";
import { Template } from "../../types/template";
import { useGetTemplateByIdQuery } from "../../services/template.service";
import { Edit } from "@mui/icons-material";
import { nl2br } from "../../utils/helper";
import { ContactPhone, EmailOutlined, ExpandMore } from "@mui/icons-material";
import MapPreview from "../../components/MapPreview";

const TemplateHeader = ({ data }: { data?: Template }) => {
  const navigate = useNavigate();

  const { city, state, zip } = React.useMemo(() => {
    if (!!data?.customer) {
      return data?.customer;
    } else if (!!data?.location) {
      return data?.location;
    } else
      return {
        city: "?",
        state: "?",
        zip: "?",
      };
  }, [data]);

  return (
    <>
      <Stack direction={"row"} alignItems="flex-start">
        <Stack direction="column" sx={{ mb: 4 }} flex={1}>
          <Stack direction="row">
            <Typography variant="h5">{`#${data?.id || ""} ${
              data?.title || ""
            }`}</Typography>
          </Stack>
        </Stack>
        <Stack direction={"row"}>
          <Button
            size="large"
            variant="outlined"
            onClick={() => navigate(`/jobs/job-templates/${data?.id}/edit`)}
            startIcon={<Edit />}
          >
            Edit Template
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const ProjectTemplateDetailPage = () => {
  const { templateId } = useParams();

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { data, isLoading, isFetching } = useGetTemplateByIdQuery(
    parseInt(templateId!),
    {
      skip: !templateId,
    }
  );

  useTitle(`Job Template #${data?.id}`, `${data?.title} | Job Template`);

  const [lng, lat] = React.useMemo(() => {
    if (!!data?.customer) {
      return data?.customer?.coordinate?.coordinates || [];
    } else if (!!data?.location) {
      return data?.location?.coordinate?.coordinates || [];
    } else return [];
  }, [data]);

  return (
    <Box>
      <TemplateHeader data={data} />
      {(isLoading || isFetching) && <LinearProgress />}
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={8}>
          <Card>
            <CardHeader title="Scope of Work" />
            <CardContent>
              <Typography variant="body2">
                {nl2br(data?.description)}
              </Typography>
            </CardContent>
            <Divider />
            <CardHeader title="Confidential Information" />
            <CardContent>
              <Typography variant="body2">
                {nl2br(data?.confidential_information) || "n/a"}
              </Typography>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Tasks" />
            <CardContent>
              {data?.tasks?.length == 0 && (
                <Typography variant="caption">No tasks specified</Typography>
              )}
              {data?.tasks?.map((it, idx) => (
                <Accordion key={idx}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Typography>{it.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="caption">
                      {it.description || "n/a"}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Requirements" />
            <CardContent>
              <Typography variant="body2" fontWeight={500}>
                Skills
              </Typography>
              <Box sx={{ mt: 1 }}>
                {data?.skills?.length == 0 ? (
                  <Typography sx={{ fontSize: 12, color: "text.disabled" }}>
                    Not Required
                  </Typography>
                ) : (
                  data?.skills?.map((it) => (
                    <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                  ))
                )}
              </Box>
              <Typography sx={{ mt: 2 }} variant="body2" fontWeight={500}>
                Certifications
              </Typography>
              <Box sx={{ mt: 1 }}>
                {data?.certifications?.length == 0 ? (
                  <Typography sx={{ fontSize: 12, color: "text.disabled" }}>
                    Not Required
                  </Typography>
                ) : (
                  data?.certifications?.map((it) => (
                    <Chip label={it.name} key={it.id} sx={{ mr: 1, mb: 1 }} />
                  ))
                )}
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Additional Settings" />
            <CardContent>
              <Typography variant="body2">
                Number of Talents → {data?.num_of_talent}
                <br />
                Photo Required → {!!data?.is_photo_required ? "Yes" : "No"}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card>
            <CardHeader title="Work Site" />
            <CardContent sx={{ p: 0 }}>
              <MapPreview lat={lat} lng={lng} />
            </CardContent>
            <Divider />
            <CardContent>
              {!!data?.location && (
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {data?.location?.name}
                  </Typography>
                  {data?.location.address_1}, {data?.location.city},{" "}
                  {data?.location.state} {data?.location.zip}
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhone
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.location?.primary_contact_name} -{" "}
                    {data?.location?.primary_contact_phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.location?.primary_contact_email}
                  </Stack>
                </Box>
              )}
              {!!data?.customer && (
                <Box fontSize={14}>
                  <Typography variant="body1" fontWeight={500}>
                    {data?.customer?.first_name}
                  </Typography>
                  {data?.customer.physical_address_1}, {data?.customer.city},{" "}
                  {data?.customer.state} {data?.customer.zip}
                  <br />
                  <Stack direction={"row"} alignItems="center" sx={{ mt: 1 }}>
                    <ContactPhone
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.customer?.phone_number}
                  </Stack>
                  <Stack direction={"row"} alignItems="center">
                    <EmailOutlined
                      fontSize={"small"}
                      color={"disabled"}
                      sx={{ mr: 1 }}
                    />
                    {data?.customer?.email}
                  </Stack>
                </Box>
              )}
            </CardContent>
          </Card>
          <Card sx={{ mt: 1 }}>
            <CardHeader title="Meta" />
            <CardContent>
              <Typography variant="body2">
                Created At →
                {dayjs(data?.created_at).format("MMM DD, YYYY hh:mm A z")}
                <br />
                Last Updated →
                {dayjs(data?.updated_at).format("MMM DD, YYYY hh:mm A z")}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
export default ProjectTemplateDetailPage;
